import axios from 'axios'
import Cookies from 'js-cookie';
export default {
    // 日付をフォーマット
    date_format(date){
        let result = ""
      if(typeof(date) == "string"){
        result = date.substr(0, 10);
      }
      return result
    },
    // モデル名のインデックスを返す
    return_item_index(array,key){
        const index = array.findIndex(item => item.model_name == key);
        return index
    },
    // データをセレクトリストに変換
    create_select_list: function(source, symbol_target) {
        let id = 0
        let list_obj = {}
        let result = []
        for (const data of source) {
            id +=1
            list_obj = {
            id:id,
            value:data["id"],
            symbol:data[symbol_target],
            }
            result.push(list_obj)
        }
        return result
    },
    async test(){
        console.log("test")
    },
    // URLを返す
    return_url(url){
        let result = `${process.env.VUE_APP_HOST_DOMAIN}${url}`
        // let result = `http://localhost:8080/api/${url}`
        return result
    },
    // S3のパスを取得
    return_s3_path(path){
        return `${process.env.VUE_APP_S3_PATH}${path}`
    },
    // apiのGetメソッド
    async axios_get(path){
        try {
            let url = this.return_url(path)
            let response = await axios.get(url);
            return response;
        } catch (error) {
            let response = {}
            response.status = error.response ? error.response.status : 500 
            response.message = error.response ? error.response.data : "サーバーエラー"
            return response;
        }
    },  
    // apiのPostメソッド
    async axios_post(path,post_data){
        try {
            let url = this.return_url(path)
            let response = await axios.post(url,post_data);
            return response;
        } catch (error) {
            if (error.response && error.response.status == "401") {
                Cookies.remove('user_id')
                Cookies.remove('user_type')
                Cookies.remove('user_name')
            }
            let response = {}
            response.status = error.response ? error.response.status : 500 
            response.message = error.response ? error.response.data : "サーバーエラー"
            return response;
        }
    },      
    // 画像を含むデータのPOST
    async axios_file_post(path,post_data,uploadfiles){
        try {
            let formData = new FormData();
            let jsonData = JSON.stringify(post_data);
            formData.append("document", jsonData);
            for (const key in uploadfiles) {
                formData.append(key, uploadfiles[key])
            }
            let url = this.return_url(path)
            let response = await axios.post(url,formData,{'content-type': 'multipart/form-data'});
            return response;
        } catch (error) {
            let response = {}
            response.status = error.response ? error.response.status : 500 
            response.message = error.response ? error.response.data : "サーバーエラー"
            return response;
        }
    },      
}