<template>
    <div class="image_block" id="customer_scratch">
        <div class="image_box">
            <img id="bg_image" style="height: 900px;" :src="$commonFunction.return_s3_path(scratch_data.bg_image)">
            <div class="grid-container" style="top:200px">
                <div class="grid-item" v-for="index in board[0]">
                    <img :src="return_image_path(index)">
                </div>
                <div class="grid-item" v-for="index in board[1]">
                    <img :src="return_image_path(index)">
                </div>
                <div class="grid-item" v-for="index in board[2]">
                    <img :src="return_image_path(index)">
                </div>
            </div>
            <div class="button_box" style="top:620px" :style="{ borderColor: scratch_data.color }">
                <button @click="start_test" :style="{ backgroundColor: scratch_data.color }">テスト開始</button>
                <br>
                <p style="color: white;">UA: {{userAgent}}</p>
                <p v-if="is_secret" style="color: white;">シークレットモードです</p>
                <p v-if="!is_secret" style="color: white;">通常です</p>
                <p style="text-align: right;color: white;">base_quota: 120000000</p>
                <p style="text-align: right;color: white;">quota: {{quota}}</p>
            </div>
        </div>
        <div class="prize_box" style="top: 440px;height: 200px;">
            <div class="prize_row">
                <img :src="$commonFunction.return_s3_path(scratch_data.prize1_image)">
                <p style="background: black;padding: 10px; margin-bottom:0;margin-top:0">{{count_data.p1_current}}/{{count_data.p1_start}}</p>
            </div>
            <div class="prize_row">
                <img :src="$commonFunction.return_s3_path(scratch_data.prize2_image)">
                <p style="background: black;padding: 10px; margin-bottom:0;margin-top:0">{{count_data.p2_current}}/{{count_data.p2_start}}</p>
            </div>
            <div class="prize_row">
                <img :src="$commonFunction.return_s3_path(scratch_data.prize3_image)">
                <p style="background: black;padding: 10px; margin-bottom:0;margin-top:0">{{count_data.p3_current}}/{{count_data.p3_start}}</p>
            </div>
            <div class="prize_row">
                <img :src="$commonFunction.return_s3_path(scratch_data.prize4_image)">
                <p style="background: black;padding: 10px; margin-bottom:0;margin-top:0">{{count_data.p4_current}}/{{count_data.p4_start}}</p>
            </div>
            <div class="prize_row">
                <img :src="$commonFunction.return_s3_path(scratch_data.prize5_image)">
                <p style="background: black;padding: 10px; margin-bottom:0;margin-top:0">{{count_data.p5_current}}/{{count_data.p5_start}}</p>
            </div>
        </div>
        <FooterBlock :template_id="scratch_data.template_id" class="print_ignore"></FooterBlock>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            userAgent:null,
            is_secret:true,
            quota:0,
            imagePaths:[],
            board:[],
            isScratching: false,
            is_active_page: true,
            ctx: null,
            scratch_data:[],
            count_data:{
                p1_start:0,
                p1_current:0,
                p2_start:0,
                p2_current:0,
                p3_start:0,
                p3_current:0,
                p4_start:0,
                p4_current:0,
                p5_start:0,
                p5_current:0,
            }
        };
    },
    mounted() {
        this.process()
    },
    methods: {
        async process(){
            this.init_scratch();
        },
        // スクラッチの結果を取得し、配置を確定
        async init_scratch() { 
            // くじの情報を取得する
            let response = await this.$commonFunction.axios_get(`customer/scratch/detail/${this.$route.query.sid}`)
            if(response.status === 200){
                this.scratch_data = response.data.item_data[0]
                this.imagePaths.push(this.$commonFunction.return_s3_path(this.scratch_data.prize1_image))
                this.imagePaths.push(this.$commonFunction.return_s3_path(this.scratch_data.prize2_image))
                this.imagePaths.push(this.$commonFunction.return_s3_path(this.scratch_data.prize3_image))
                this.imagePaths.push(this.$commonFunction.return_s3_path(this.scratch_data.prize4_image))
                this.imagePaths.push(this.$commonFunction.return_s3_path(this.scratch_data.prize5_image))
                this.count_data.p1_start = response.data.item_data[0]["1p_start_count"]
                this.count_data.p2_start = response.data.item_data[0]["2p_start_count"]
                this.count_data.p3_start = response.data.item_data[0]["3p_start_count"]
                this.count_data.p4_start = response.data.item_data[0]["4p_start_count"]
                this.count_data.p5_start = response.data.item_data[0]["5p_start_count"]
            }
            // ユーザーのブラウザ情報を表示
            this.userAgent = navigator.userAgent;
            if ('storage' in navigator && 'estimate' in navigator.storage) {
                navigator.storage.estimate().then(estimate => {
                    const quota = estimate.quota;
                    this.quota = quota
                    if (quota > 120000000) {
                        this.is_secret = false;
                    }
                });
            }

        },
        return_image_path(index){
            return this.imagePaths[index]
        },
        // テスト処理
        async start_test(){
            let response = await this.$commonFunction.axios_get(`user/scratch/test/${this.$route.query.sid}`)
            if(response.status == 200){
                if(response.data.prize == "1p_start_count"){
                    this.count_data.p1_current += 1 
                } else if(response.data.prize == "2p_start_count"){
                    this.count_data.p2_current += 1 
                } else if(response.data.prize == "3p_start_count"){
                    this.count_data.p3_current += 1 
                } else if(response.data.prize == "4p_start_count"){
                    this.count_data.p4_current += 1 
                } else if(response.data.prize == "5p_start_count"){
                    this.count_data.p5_current += 1 
                }
                const matrix = [];
                for (let i = 0; i < 9; i += 3) {
                    const row = response.data.pattern.substring(i, i + 3).split('').map(Number);
                    matrix.push(row);
                }
                this.board = matrix
            }

        }
    }
}
</script>

<style>
</style>