<template>
  <div class="image_block" id="server_error">
    <div class="image_box">
      <img id="bg_image" src="/img/scratch_bg1.png">
        <div id="temp_logo_box">
            <img src="/img/temp_logo1.png">
        </div>
        <div class="container" id="policy" style="margin-top: 120px;">
            <p>管理画面はスマートフォンに対応していません</p>
            <p>PCでご確認ください</p>
        </div>
    </div>
</div>
</template>

