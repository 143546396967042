<template>
  <section id="setting_list_block">
    <div>
      <p>{{ $t(block_data.setting_block.item_name) }}</p>
      <table class="table" border="1" style="border-collapse: collapse" align="center">
        <tbody>
          <tr v-for="(data, index) in block_data.setting_block.item_models" :key="'setting_table_' + index" >
            <td><p>{{ $t(data.label) }}</p></td>
            <td v-if="data.editable"><input type="text" v-model="block_data.setting_block.item_data[data.model_name]"/></td>
            <td v-if="!data.editable">{{ block_data.setting_block.item_data[data.model_name] }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="button_box">
      <button class="btn_blue" @click="post_edit_data()">{{ $t("CommonSave")}}</button>
    </div>
  </section>
</template>

<script>
export default {
  props: ["block_data"],
  data() {
    return {
    }
  },
  methods: {
  },
};
</script>

<style>
  #setting_list_block p {color: #1A7484;font-weight: bold;margin-bottom: 1rem;}
  #setting_list_block table {width: auto;margin: 20px 0;}
  #setting_list_block td {padding: 5px;background-color: white;}
  #setting_list_block td input {width: 400px;font-size: 100%;padding: 5px;box-sizing: border-box;-moz-box-sizing: border-box;-webkit-box-sizing: border-box;}
  #setting_list_block .button_box button {cursor: pointer;width: 100%;border: none;text-align: center;display: inline-block;padding: 0.5em 1em;text-decoration: none;border-radius: 15px;}
  #setting_list_block .button_box {width: 20%;text-align: center;}
  #setting_list_block .btn_blue {  color: white;  background: rgb(30, 187, 226);}
  #setting_list_block td p {color: black;font-weight: unset;margin: 0;}
</style>