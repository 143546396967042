<template>
  <LoadingBlock v-model="block_data.loading_block" v-bind:loading_block="block_data.loading_block"></LoadingBlock>
  <div v-show="!block_data.loading_block.is_loading">
    <SampleHeaderBlock></SampleHeaderBlock>
    <div class="container">
      <PageGuideBlock :block_data="block_data"></PageGuideBlock>
      <TabIndexBlock :block_data="block_data"></TabIndexBlock>
      <TabContentBlock v-show="block_data.tab_index_block.index == 1" :list_items="block_data.tab_content_block.list_fruits"></TabContentBlock>
      <TabContentBlock v-show="block_data.tab_index_block.index == 2" :list_items="block_data.tab_content_block.list_vegetable"></TabContentBlock>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
    data() {
      return {
        block_data:{
            query_param:{},
            loading_block:{
                is_loading:false
            },
            return_block:{
                return_page_name:"SampleTopPage",
                return_page_is_query:true,
                page_guides:[
                    {
                        name:"PageNameTop",
                        link:"SampleTopPage",
                        is_query:false,
                    },
                    {
                        name:"PageNameSampleList",
                        link:"",
                        is_query:false,
                    },
                ],
            },
            tab_index_block:{
                index:1,
                labels:[
                    {index:1,label:"DataNameSampleFruits"},
                    {index:2,label:"DataNameSampleVegetable"},
                ],
            },
            tab_content_block:{
                list_fruits:{
                    modal_condition:0,
                    list_search_property:{
                        label:"DataNameSampleFruits",
                        source_list:[],
                        source_list_default:[],
                        is_search:false,
                        search_word: "",
                    },
                    add_menu_button_block: [
                        {
                            label: "CommonRegister",
                            method_type: "add",
                            link: "",
                        },
                    ],
                    current_page:1,
                    per_page:5,
                    post_add_url:"sample/fruits/add",
                    post_edit_url:"sample/fruits/edit",
                    post_delete_url:"sample/fruits/delete",
                    select_data:{},
                    item_name:"DataNameSampleFruits",
                    list_buttons:[
                        {
                            type:"edit",
                            label:"CommonEdit"
                        },
                        {
                            type:"delete",
                            label:"CommonDelete"
                        }
                    ],
                    item_data:[],
                    item_data_default:[],
                    item_models:[
                        {
                            label:"LabelID",
                            model_name:"id",
                            type:"text",
                            value:"",
                            add_modal_visible:false,
                            list_visible:true,
                            editable:false,
                        },
                        {
                            label:"LabelName",
                            model_name:"name",
                            type:"text",
                            value:"",
                            add_modal_visible:true,
                            list_visible:true,
                            editable:false,
                        },
                        {
                            label:"LabelPrice",
                            model_name:"price",
                            type:"price",
                            value:"",
                            add_modal_visible:true,
                            list_visible:true,
                            editable:true,
                        },
                        {
                            label:"LabelVegetableName",
                            model_name:"vegetable_id",
                            type:"list",
                            value:"",
                            option:[],
                            add_modal_visible:true,
                            list_visible:false,
                            editable:true,
                        },
                        {
                            label:"LabelVegetableName",
                            model_name:"vegetable_name",
                            type:"text",
                            value:"",
                            add_modal_visible:false,
                            list_visible:true,
                            is_list_value:true,
                            editable:true,
                        },
                    ]
                }, 
                list_vegetable:{
                    modal_condition:0,
                    list_search_property:{
                        label:"DataNameSampleVegetable",
                        source_list:[],
                        source_list_default:[],
                        is_search:false,
                        search_word: "",
                    },
                    add_menu_button_block: [
                        {
                            label: "CommonRegister",
                            method_type: "add",
                            link: "",
                        },
                    ],
                    current_page:1,
                    per_page:5,
                    post_add_url:"sample/vegetable/add",
                    post_edit_url:"sample/vegetable/edit",
                    post_delete_url:"sample/vegetable/delete",
                    select_data:{},
                    item_name:"DataNameSampleVegetable",
                    list_buttons:[
                        {
                            type:"edit",
                            label:"CommonEdit"
                        },
                        {
                            type:"delete",
                            label:"CommonDelete"
                        }
                    ],
                    item_data:[],
                    item_data_default:[],
                    item_models:[
                        {
                            label:"LabelID",
                            model_name:"id",
                            type:"text",
                            value:"",
                            add_modal_visible:false,
                            list_visible:true,
                            editable:false,
                        },
                        {
                            label:"LabelName",
                            model_name:"name",
                            type:"text",
                            value:"",
                            add_modal_visible:true,
                            list_visible:true,
                            editable:true,
                        },
                    ]
                }  
            }
        }
      }
    },
    created () {
        this.process()
    },
    methods: {
        async process() {
            this.block_data.loading_block.is_loading = true
            // サンプル野菜データ取得
            let response = await axios.get('http://localhost:8080/api/sample/vegetable/list')
            this.block_data.tab_content_block.list_vegetable.item_data = response.data.item_data
            this.block_data.tab_content_block.list_vegetable.item_data_default = response.data.item_data
            // セレクトリスト用のデータを用意
            let select_list = this.$commonFunction.create_select_list(response.data.item_data,"name")
            // サンプルフルーツデータ取得
            response = await axios.get('http://localhost:8080/api/sample/fruits/list')
            this.block_data.tab_content_block.list_fruits.item_data = response.data.item_data
            this.block_data.tab_content_block.list_fruits.item_data_default = response.data.item_data
            // フルーツに野菜の情報をセット
            let target_index = this.$commonFunction.return_item_index(this.block_data.tab_content_block.list_fruits.item_models,"vegetable_id")
            this.block_data.tab_content_block.list_fruits.item_models[target_index].value = select_list[0].value
            this.block_data.tab_content_block.list_fruits.item_models[target_index].option = select_list

            this.block_data.loading_block.is_loading = false
        },
    }

};
</script>

