<template>
    <div class="view_port">
        <div style="position: relative;">
            <img :src="s3_path + scratch_block.image_path" style="width: 100%;">
            <div class="grid-container">
                <div class="grid-item" v-for="icon in board[0]">
                    <img v-if="icon == 1" src="/img/scratch_1.png">
                    <img v-if="icon == 2" src="/img/scratch_2.png">
                    <img v-if="icon == 3" src="/img/scratch_3.png">
                </div>
                <div class="grid-item" v-for="icon in board[1]">
                    <img v-if="icon == 1" src="/img/scratch_1.png">
                    <img v-if="icon == 2" src="/img/scratch_2.png">
                    <img v-if="icon == 3" src="/img/scratch_3.png">
                </div>
                <div class="grid-item" v-for="icon in board[2]">
                    <img v-if="icon == 1" src="/img/scratch_1.png">
                    <img v-if="icon == 2" src="/img/scratch_2.png">
                    <img v-if="icon == 3" src="/img/scratch_3.png">
                </div>
              </div>              
            <div class="item_view">
                <h2>{{scratch_block.title}}</h2>
                <p>3列Hit!!: {{scratch_block.first_prize_label}}</p>
                <p>2列Hit!: {{scratch_block.second_prize_label}}</p>
                <p>1列Hit: {{scratch_block.third_prize_label}}</p>
                <p>Hit無し: {{scratch_block.miss_prize_label}}</p>
                <br>
                <p>スクラッチNo.: {{scratch_number}}</p>
            </div>
        </div>
    </div>
    <FooterBlock></FooterBlock>
  </template>
  
  <script>
  export default {
    data() {
      return {
        s3_path:"https://s3-ap-northeast-1.amazonaws.com/sasaeru-test/",
        scratch_block:{},
        scratch_number:0,
        board:[],
        isScratching: false,
        ctx: null,
      };
    },
    mounted() {
        this.process()
    },
    methods: {
        async process(){
            await this.init_scratch();
            await this.generatePattern();
        },
        // くじデータを取得して設定
        async init_scratch() {
            let response = await this.$commonFunction.axios_get(`user/scratch/detail/${this.$route.query.s_id}`)
            if(response.status == 200){
                this.scratch_block = response.data.item_data[0]
            }
        },
        // パネルを生成
        async generatePattern() {
            this.board = Array.from({ length: 3 }, () => Array(3).fill(0));
            this.scratch_number = this.generateSeed()
            let seed = this.generateSeed()
            let marks = this.shuffleArray([1, 2, 3],seed);
            const prob = this.seededRandom(seed) * 100;
            console.log(`seed :${seed}`)
            console.log(`prob :${prob}`)
            

            const fillBoard = (rowCondition, colCondition, value) => {
                this.board.forEach((r, rowIndex) => {
                    r.forEach((_, colIndex) => {
                    if (rowCondition(rowIndex) && colCondition(colIndex)) {
                        this.board[rowIndex][colIndex] = value;
                    }
                });
            });
            };
            let pattern_type
            if (prob <= this.scratch_block.first_prize_percent) {
                pattern_type = "A"
                this.processStateA(marks,seed);
            } else if (prob <= this.scratch_block.first_prize_percent + this.scratch_block.second_prize_percent) {
                pattern_type = "B"
                this.processStateB(marks,seed);
            } else if (prob <= this.scratch_block.first_prize_percent + this.scratch_block.second_prize_percent + this.scratch_block.third_prize_percent) {
                pattern_type = "C"
                this.processStateC(marks,seed);
            } else {
                pattern_type = "D"
                this.processStateD(marks,seed);
            }
            // return pattern_type
            console.log(`pattern_type :${pattern_type}`)
            console.log(`board :${this.board}`)
        },

        // 各状態の処理を個別のメソッドとして定義
        processStateA(marks,seed) {
            marks = this.shuffleArray([1, 2, 3],seed);
            let [mark_1, mark_2, mark_3] = marks;
            this.board.forEach(row => row[1] = mark_1);
            this.board[1] = Array(3).fill(mark_1);
            let additionalRows = [0, 2];
            let additionalRow = additionalRows[Math.floor(this.seededRandom(seed) * additionalRows.length)];
            this.board[additionalRow].fill(mark_1);
            this.board.forEach((r, rowIndex) => {
                r.forEach((_, colIndex) => {
                    if (rowIndex !== 1 && colIndex !== 1 && rowIndex !== additionalRow) {
                        this.board[rowIndex][colIndex] = this.seededRandom(seed) < 0.5 ? mark_2 : mark_3;
                    }
                });
            });
        },

        processStateB(marks,seed) {
            let mark_1 = marks[0];
            let mark_3 = marks[2];
            let row = Math.floor(this.seededRandom(seed) * 3);
            let col = Math.floor(this.seededRandom(seed) * 3);
            this.board = this.board.map(() => Array(3).fill(marks[2]));
            this.board[row].fill(marks[0]);
            this.board.forEach(r => r[col] = marks[0]);
        },

        processStateC(marks,seed) {
            let mark_1 = marks[Math.floor(this.seededRandom(seed) * marks.length)];
            let remaining_marks = marks.filter(mark => mark !== mark_1);
            let mark_2 = remaining_marks[0];
            let mark_3 = remaining_marks[1];
            let list = [
                [mark_1, mark_1, mark_1],
                [mark_2, mark_2, mark_3],
                [mark_2, mark_3, mark_2]
            ];
            list = list.sort(() => this.seededRandom(seed) - 0.5);
            this.board = list
        },
        processStateD(marks,seed) {
            let mark_1 = marks[Math.floor(this.seededRandom(seed) * marks.length)];
            let remaining_marks = marks.filter(mark => mark !== mark_1);
            let mark_2 = remaining_marks[0];
            let mark_3 = remaining_marks[1];
            let list = [
                [mark_1, mark_1, mark_3],
                [mark_1, mark_2, mark_3],
                [mark_2, mark_3, mark_2]
            ];
            list = list.sort(() => this.seededRandom(seed) - 0.5);
            this.board = list
        },

        shuffleArray(array,seed) {
            for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(this.seededRandom(seed) * (i + 1));
                [array[i], array[j]] = [array[j], array[i]];
            }
            return array;
        },
        isUniform(array) {
            return new Set(array).size === 1;
        },
        // 日付とUAからシードを取得
        generateSeed() {
            let seed = this.$route.query.s_no
            return seed;
        },
        // シードを指定して、ランダム数を生成
        seededRandom(seed) {
            var x = Math.sin(seed++) * 10000;
            return x - Math.floor(x);
        }

      
    },
  }
</script>

<style>
    .view_port{
        width: 400px;
        margin: 0 auto;
    }
</style>