<template>
  <SampleHeaderBlock></SampleHeaderBlock>
  <div class="container">
      <section id="login_block">
        <div class="image_box">
          <img src="/img/logo.png" />
        </div>
        <p>{{ $t("PageNameSampleLogin") }}</p>
        <div>
          <div class="input_section">
            <input :placeholder="$t('PlaceholderUsername')" type="mail" name="username" v-model='post_data.username'>
            <input :placeholder="$t('PlaceholderPassword')" name="password" type="password" v-model='post_data.password'>  
          </div>
          <div class="button_box">
            <button class="btn_blue" @click="login">{{ $t("CommonLogin")}}</button>
          </div>
          <div v-if="is_error">
            <p class="error_message_color">{{$t("ErrorLoginFailed")}}</p>
            <p class="error_message_color">{{$t("ErrorPasswordMismatch")}}</p>  
          </div>
          <p>
            <router-link :to="{name:page_data.pass_reset_page_name}">{{$t("CommonPassReset")}}</router-link>
          </p>
        </div>
      </section>
  </div>
</template>

<script>
import axios from 'axios'
import Cookies from 'js-cookie';
export default {
  data () {
    return {
      page_data:{
        pass_reset_page_name:"",
      },
      block_data:{
        return_block:{
          return_page_name:"SampleTopPage",
          return_page_is_query:true,
          page_guides:[
            {
                name:"PageNameTop",
                link:"SampleTopPage",
                is_query:false,
            },
            {
                name:"PageNameSampleLogin",
                link:"",
                is_query:false,
            },
          ],
        },
      },
      post_data:{
        username: "",
        password: "",
        email: "aaa@co.jp",
        user_type: 0,
      }
    }
  },
  methods: {
    async login () {
      const response = await axios.post('api/authorization/login', this.post_data)
      if (response.status === 200) {
        Cookies.set('user_id', response.data.user_id);
        Cookies.set('user_type', response.data.user_type);
      } else {
        console.error('Login failed')
      }
    },
    async dashboard () {
      const response = await axios.post('api/user/userinfo/test')
      if (response.status === 200) {
        console.log("dash success!!!")
      } else {
        console.log("dash error!!!")
      }
    },
    async logout () {
      const response = await axios.post('api/authorization/logout')
      if (response.status === 200) {
        Cookies.remove('user_id')
        Cookies.remove('user_type')
      } else {
        console.log("error!!!")
      }
    }
  }
}
</script>

<style>
  #login_block{margin: 20px auto;padding: 20px;border-radius: 15px;}
  #login_block p{text-align: center;}
  #login_block .image_box{margin: 20px auto;text-align: center;}
  #login_block .input_section {display: grid;}
  #login_block .input_section input {width: 40%;margin: 10px auto;padding: 10px;}
  #login_block .button_box {margin: 20px auto;width: 20%;text-align: center;}
  #login_block .button_box button{width: 100%;border: none;text-align: center;display: inline-block;padding: 0.5em 1em;text-decoration: none;border-radius: 15px;}
  #login_block .button_box .btn_blue {color: white;background: rgb(30, 187, 226);font-weight: bold;font-size: 1.2rem;}
  #login_block p {margin-top: 0;margin-bottom: 1rem;}
  #login_block img {vertical-align: middle;}
</style>