<template>
  <section id="search_block_list" v-show="list_items.list_search_property">
    <div class="search_button button_box">
      <p>{{ $t(list_items.list_search_property.label)}}{{ $t("CommonSearch")}}</p>
      <input
        type="text"
        name="search_word"
        v-model="list_items.list_search_property.search_word"
        :placeholder="$t('CommonSearchKeyword')"
      />
      <button class="btn_search" v-show="!list_items.list_search_property.is_search" @click="start_search">{{ $t("CommonSearch")}}</button>
      <button class="btn_search_reset" @click="reset_search">{{ $t("CommonReset")}}</button>
    </div>
  </section>
</template>

<script>
export default {
  props: ["list_items"],
  data() {
    return {
    }
  },
  methods: {
    // 検索処理
    start_search(){
      let source_items = []
      let new_items = []
      this.list_items.list_search_property.is_search = true
      source_items = this.list_items.item_data_default
      // 検索ワードでフィルタ
      if (this.search_word != ""){
        new_items = []
        for (const item of source_items) {
          if(item.name.includes(this.list_items.list_search_property.search_word)){
            new_items.push(item)
          }
        }
      } else {
        new_items = source_items
      }
      this.list_items.item_data = new_items
    },
    // 検索条件のリセット
    reset_search(){
      this.list_items.list_search_property.is_search = false
      this.list_items.item_data = this.list_items.item_data_default
      // this.select_query=0
      this.list_items.list_search_property.search_word = ""
    },
  },
};
</script>
