<template>
    <LoadingBlock v-model="block_data.loading_block" v-bind:loading_block="block_data.loading_block"></LoadingBlock>
    <div v-show="!block_data.loading_block.is_loading">
      <SampleHeaderBlock></SampleHeaderBlock>
      <div class="container">
        <PageGuideBlock :block_data="block_data"></PageGuideBlock>
        <div class="detail_block">
            <p>決済完了後にページが表示されなかった場合、下記に決済IDを入力し、送信することで結果のURLを表示できます</p>
        </div>
        <div class="detail_block">
            <section id="scratch_block" style="width: 80%;">
                <div class="input_block">
                    <p>PayPay決済ID</p>
                    <input type="text" v-model="order_id" placeholder="0123456789..."/>
                </div>
                <section id="menu_button" style="justify-content: unset;">
                    <div class="button_box">
                        <button class="btn_blue" @click="get_result">送信</button>
                    </div>
                </section>
                <p>結果再表示URL: <br>{{qr_url}}</p>
                <p class="error_message" v-for="error in error_messages">{{error}}</p>
            </section>
        </div>
      </div>
    </div>
  </template>
  
<script>
import PCTableParts from "@/components/blocks/default/PCTableParts";
import AddModalParts from "@/components/blocks/default/AddModalParts";
import SelectModalParts from "@/components/blocks/default/SelectModalParts";
import QrcodeVue3 from 'qrcode-vue3';
  export default {
    components: {
        PCTableParts,
        AddModalParts,
        SelectModalParts,
        'qrcode-vue3': QrcodeVue3
    },
      data() {
        return {
            s3_path:"https://s3-ap-northeast-1.amazonaws.com/sasaeru-test/",
            qr_url:"",
            scratch_detail:[],
            error_messages:[],
            order_id:[],
          block_data:{
              query_param:{},
              loading_block:{
                  is_loading:false
              },
              return_block:{
                  return_page_name:"UserTopPage",
                  return_page_is_query:true,
                  page_guides:[
                      {
                          name:"スクラッチ結果再表示",
                          link:"",
                          is_query:false,
                      },
                  ],
              },
              tab_index_block:{
                  index:1,
                  labels:[
                      {index:1,label:"スクラッチ結果再表示"},
                  ],
              },
          }
        }
      },
      methods: {
          async get_result() {
            this.error_messages = []
            
            this.block_data.loading_block.is_loading = true

            let response = await this.$commonFunction.axios_get(`user/scratch/payment/log/${this.order_id}`)
            if(response.status == 200){
                let query_parm = {
                    sid:response.data.hash_id,
                    scratch_code:response.data.scratch_code
                }
                // this.qr_url = `http://localhost:8080/customer/scratch?sid=${response.data.hash_id}&scratch_code=${response.data.scratch_code}`
                this.qr_url = `https://scratch-app.jp/customer/scratch?sid=${response.data.hash_id}&scratch_code=${response.data.scratch_code}`
            } else {
                this.error_messages.push("決済結果を確認できませんでした")
            }
            this.block_data.loading_block.is_loading = false
        },
      }
  
  };
  </script>
  