<template>
    <LoadingBlock v-model="block_data.loading_block" v-bind:loading_block="block_data.loading_block"></LoadingBlock>
    <div v-show="!block_data.loading_block.is_loading">
      <SampleHeaderBlock></SampleHeaderBlock>
      <div class="container" id="user_home">
        <section id="search_block_list">
            <div class="search_button button_box">
                <p>スクラッチ一覧検索</p>
                <input type="text" name="search_word" placeholder="キーワード" v-model="block_data.tab_content_block.list_questions.list_search_property.search_word">
                <button class="btn_search" @click="start_search">検索</button>
                <button class="btn_search_reset" @click="reset_search">リセット</button>
            </div>
        </section>
        <section id="menu_button">
            <div class="button_box">
                <button class="btn_blue" @click="link_to('UserScratchAddPage',null)">新規登録</button>
            </div>
            <div class="button_box">
                <button style="background-color: #4bc852;" class="btn_blue" @click="link_to('UserPaymentLogPage',null)">結果再表示</button>
            </div>
        </section>
        <!-- テーブル -->
        <div class="scratch_section">
            <div class="scratch_block" v-for="item in block_data.tab_content_block.list_questions.item_data">
                <div class="image-block" @click="link_to('UserScratchDetailPage',item.hash_id)">
                    <img id="bg_image" :src="$commonFunction.return_s3_path(item.bg_image)">
                </div>
                <div class="text-block">
                    <div class="date">{{$commonFunction.date_format(item.created)}}</div>
                    <div class="title">{{item.title}}</div>
                </div>
                <div class="button_box">
                    <button class="btn_blue" @click="data_select(item,3)">削除</button>
                </div>
            </div>
        </div>
        <div id="overlay" v-if="block_data.tab_content_block.list_questions.modal_condition != 0">
            <AddModalParts :list_items="block_data.tab_content_block.list_questions"></AddModalParts>
            <SelectModalParts :list_items="block_data.tab_content_block.list_questions"></SelectModalParts>
        </div>
      </div>
    </div>
    <FooterBlock></FooterBlock>
  </template>
  
  <script>
import AddModalParts from "@/components/blocks/default/AddModalParts";
import SelectModalParts from "@/components/blocks/default/SelectModalParts";
  export default {
    components: {
        AddModalParts,
        SelectModalParts,
    },
      data() {
        return {
          block_data:{
              query_param:{},
              loading_block:{
                  is_loading:false
              },
              return_block:{
                  return_page_name:"",
                  return_page_is_query:true,
                  page_guides:[
                      {
                          name:"PageNameScratch",
                          link:"",
                          is_query:false,
                      },
                  ],
              },
              tab_index_block:{
                  index:1,
                  labels:[
                      {index:1,label:"PageNameScratch"},
                  ],
              },
              tab_content_block:{
                  list_questions:{
                      modal_condition:0,
                      list_search_property:{
                          is_search:false,
                          search_word: "",
                      },
                      add_menu_button_block: [
                          {
                              label: "CommonRegister",
                              method_type: "link",
                              link: "UserScratchAddPage",
                          },
                      ],
                      current_page:1,
                      per_page:5,
                      detail_page_name:"UserScratchDetailPage",
                      detail_key:"hash_id",
                      detail_key_label:"s_id",
                      post_add_url:"/user/scratch/add",
                      post_delete_url:"/user/scratch/delete",
                      select_data:{},
                      item_name:"PageNameScratch",
                      list_buttons:[
                          {
                              type:"detail",
                              label:"CommonDetail"
                          },
                          {
                              type:"delete",
                              label:"CommonDelete"
                          }
                      ],
                      item_data:[],
                      item_data_default:[],
                      item_models:[
                          {
                              label:"LabelID",
                              model_name:"id",
                              type:"text",
                              value:"",
                              add_modal_visible:false,
                              list_visible:true,
                              editable:false,
                          },
                          {
                              label:"LabelName",
                              model_name:"title",
                              type:"text",
                              value:"",
                              add_modal_visible:true,
                              list_visible:true,
                              editable:false,
                          },
                      ]
                  }  
              }
          }
        }
      },
      created () {
          this.process()
      },
      methods: {
        async process() {
            this.block_data.loading_block.is_loading = true
            let response = await this.$commonFunction.axios_get('user/scratch/list')
            if(response.status == 200){
                this.block_data.tab_content_block.list_questions.item_data = response.data.item_data
                this.block_data.tab_content_block.list_questions.item_data_default = response.data.item_data
                this.block_data.loading_block.is_loading = false
            } else if (response.status == 401){
                this.$router.push({name:"UserLoginPage"});
            } else if (response.status == 500){
                this.$router.push({name:"ServerErrorPage"});
            }

            
        },
        link_to(page_name,id){
            if(id){
                this.$router.push({name:page_name,query: {s_id:id}}); 
            } else {
                this.$router.push({name:page_name}); 
            }
        },
        // 検索処理
        start_search(){
            let source_items = []
            let new_items = []
            this.block_data.tab_content_block.list_questions.list_search_property.is_search = true
            source_items = this.block_data.tab_content_block.list_questions.item_data_default
            // 検索ワードでフィルタ
            if (this.search_word != ""){
                new_items = []
                for (const item of source_items) {
                if(item.title.includes(this.block_data.tab_content_block.list_questions.list_search_property.search_word)){
                    new_items.push(item)
                }
                }
            } else {
                new_items = source_items
            }
            this.block_data.tab_content_block.list_questions.item_data = new_items
        },
        // 検索条件のリセット
        reset_search(){
            this.block_data.tab_content_block.list_questions.list_search_property.is_search = false
            this.block_data.tab_content_block.list_questions.item_data = this.block_data.tab_content_block.list_questions.item_data_default
            this.block_data.tab_content_block.list_questions.list_search_property.search_word = ""
        },
        // 親のセレクトデータに値を代入する
        data_select(select_data,condition) {
            this.block_data.tab_content_block.list_questions.modal_condition = condition
            this.block_data.tab_content_block.list_questions.select_data = select_data
        },
      }
  
  };
  </script>

  <style>
    .scratch_section{margin: 40px auto;display: flex;flex-wrap: wrap;justify-content: space-around;}
    .scratch_block{width: 22%;max-width: 300px;max-height: 450px;}
    .scratch_block img{width: 100%;height:100%; object-fit: scale-down; text-align: center;}
    .text-block{padding: 10px;background-color: #2699FB;color: white;margin-top: 10px;}
    .image-block{}


    .scratch_block .button_box{margin: 10px auto;width: 45%;text-align: center;}
    .scratch_block .button_box button{color: white;background: #fb2926;height: 40px;cursor: pointer;width: 100%;border: none;text-align: center;display: inline-block;padding: 0.5em 1em;text-decoration: none;border-radius: 5px;font-weight: bold;}

    #user_home #bg_image{
        width: 100%;
        height: 200px;
        position: relative;
        top: 0;
        transform: none;
        object-fit: cover;
        left: 0;
    }
  </style>
  
  