<!-- 登録のモーダル -->
<template>
  <div class="modal-content fadeIn" v-if="list_items.modal_condition == 1">
    <div class="modal-headline">
      <button @click="modal_control(0)">×</button>
    </div>
    <div class="modal-main">
      <h2>{{ $t(list_items.item_name) }}{{ $t("CommonAdd") }}</h2>
      <div 
          v-for="(data, index) in list_items.item_models"
          :key="'modal_add_' + index" 
          v-show="data.add_modal_visible"
      >
        <!-- リスト以外の入力 -->
        <div class="content" v-if="data.type != 'list' && data.type != 'price' && data.type != 'textarea' && data.type != 'file'">
          <p>{{ $t(data.label) }}:</p>&nbsp;
          <input :type="data.type" v-model="data.value"/><br />
        </div>
        <div class="content" v-if="data.type != 'list' && data.type == 'price'">
          <p>{{ $t(data.label) }}:</p>&nbsp;
          <input type="number" v-model="data.value"/><br />
        </div>
        <div class="content" v-if="data.type != 'list' && data.type == 'textarea'">
          <p>{{ $t(data.label) }}:</p>&nbsp;<br>
          <textarea v-model="data.value"></textarea>
        </div>
        <!-- ファイルの入力 -->
        <div class="content" v-if="data.type == 'file'">
          <p>{{ $t(data.label) }}:</p>&nbsp;
          <input :name="index" @change="changeFile(index, $event)" type="file"/>
        </div>
        <!-- リストの入力 -->
        <div class="content" v-if="data.type == 'list'">
          <p>{{ $t(data.label) }}:</p>&nbsp;
          <span>
            <select v-model="data.value">
              <option v-for="option in data.option" v-bind:value="option.value" v-bind:key="option.id">
                {{ option.symbol }}
              </option>
            </select>
          </span>
        </div>
      </div>
      <div class="button_box">
        <button class="btn_blue" @click="modal_control(5)">{{ $t("CommonAdd")}}</button>
      </div>
      <div class="error_message" v-for="error_message in error_messages">
        <p>{{ $t(error_message)}}</p>
      </div>
    </div>
  </div>
  <!-- 確認のモーダル -->
  <div class="modal-content fadeIn" v-if="list_items.modal_condition == 5">
    <div class="modal-headline">
      <button @click="modal_control(0)">×</button>
    </div>
    <div class="modal-main">
      <h2>{{ $t("CommonAddConfirm") }}</h2>
      <div 
        class="content" 
        v-for="(data, index) in list_items.item_models" 
        v-show="data.add_modal_visible"
      >
        <p style="margin: 0 auto;">
          <span v-if="data.type == 'text'|| data.type == 'textarea'">{{ $t(data.label) }} : {{ data.value }}</span>
          <span v-if="data.type == 'password'">{{ $t(data.label) }} : {{ $t("CommonDummyData") }}</span>
          <span v-if="data.type == 'price'">{{ $t(data.label) }} : {{ Number(data.value).toLocaleString() }} {{ $t("CommonYen")}}</span>
          <span v-if="data.type == 'date'">{{ $t(data.label) }} : {{ $commonFunction.date_format(data.value) }}</span>
          <span v-if="data.type == 'file'">{{ $t(data.label) }} : アップロードされたファイル</span>
        </p>
      </div>
      <div v-if="!is_loading" class="button_box">
        <button class="btn_silver" @click="modal_control(1)">{{ $t("CommonBackInput")}}</button>
      </div>
      <div class="loader" v-if="is_loading"></div>
      <div v-if="!is_loading" class="button_box">
        <button class="btn_blue" @click="api_post()">{{ $t("CommonAdd")}}</button>
      </div>
      <div class="error_message" v-for="error_message in error_messages">
        <p>{{ $t(error_message)}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import imageCompression from "browser-image-compression";
export default {
  props: ["list_items"],
  data() {
    return {
      error_messages:[],
      is_loading:false,
      uploadfiles:[],
    };
  },
  // created() {
  // },
  methods: {
    // 画像処理
    async changeFile(index){
      const file = event.target.files[0] || event.dataTransfer.files[0];
      const options = {
        maxSizeMB: 1, // 最大ファイルサイズ
        maxWidthOrHeight: 800 // 最大画像幅もしくは高さ
      };
      let compFile,compFileUel
      // 画像圧縮処理
      compFile = await imageCompression(file, options);
      this.uploadfiles[index] = compFile
    },
    // データを新規登録する
    async api_post(){
      this.is_loading = true
      let post_data = {}
      for (const item_model of this.list_items.item_models){
        post_data[item_model.model_name] = item_model.value 
      }      
      // ファイルが含まれる場合、専用の処理へ
      let response
      if(this.uploadfiles.length > 0){
        response = await this.$commonFunction.axios_file_post(this.list_items.post_add_url, post_data, this.uploadfiles)
      } else {
        response = await this.$commonFunction.axios_post(this.list_items.post_add_url, post_data)
      }
      if(response.status == 200){
        this.$router.go(0);
      } else if (response.status == 401){
        Cookies.remove('user_id')
        Cookies.remove('user_type')
        Cookies.remove('user_name')
        this.$router.go(0);
      } else if (response.status == 404){
        this.is_loading = false
        this.error_messages.push("ErrorPostFailed")
      } else if (response.status == 500){
        this.is_loading = false
        this.$router.push({name:"ServerErrorPage"});
      }

      
    },
    modal_control(condition) {
      this.list_items.modal_condition = condition
    },
  },
};
</script>

<style>
  /* モーダル */
  #overlay {z-index: 3;position: fixed;top: 0;left: 0;width: 100%;height: 100%;background-color: rgba(0, 0, 0, 0.5);display: flex;align-items: center;justify-content: center;}    
  .modal-content {position: relative;border-radius: 10px;z-index: 2;width: 70%;padding: 0;background-color: #F2F1F2!important;overflow: hidden;box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);max-height: 95vh;overflow-y: scroll;}.modal-content .button_box {margin: 20px auto;}
  .modal-content button {height: 60px;font-weight: bold;text-align: center;padding: 5px 15px;border: none;font-size: 1.2rem;}
  .modal-content .modal-headline button {font-size: 2rem;background-color: #F2F1F2;color: #000;width: auto;}
  .modal-content .modal-main button {padding: 5px 15px;width: 100%;border-radius: 8px;}
  .fadeIn {animation-name: fadeInAnime;animation-duration: 0.3s;animation-fill-mode: forwards;opacity: 0;}
  .modal-headline {display: flex;justify-content: end;padding: 0 1em;align-items: center;}
  .modal-headline h1 {color: #fff;}
  .modal-main {}
  .modal-main h2{text-align: center;margin-top: 0;color: #2c469f;}
  .modal-main .content {margin: 20px auto;display: flex;width: 90%;justify-content: end;}
  .modal-main .delete-info {display: block;text-align: center;margin: 0 auto;justify-content: center;}
  .modal-main .content select{height: 60%;width: 83%;margin-top: 10px;}
  .modal-main .content input{height: 60%;width: 80%;margin-top: 10px;}
  .modal-main .content span{width:  80%;}
  .modal_image_preview{background-color: transparent;box-shadow: none;}
  .modal_image_preview .modal-headline button {background-color: transparent;font-size: 4rem;}
  .modal_image_preview .modal-main p {width: 60%;margin: 0 auto;}
  .modal_image_preview .modal-main p img{width: 100%;}
  .modal-main .content span{text-align: center;}
  .modal-main .content_label{justify-content: start;}
  #overlay .modal-content .button_box {margin: 20px auto;}
  #overlay .button_box {  width: 20%;  text-align: center;}
  #overlay .modal-content button {height: 60px;font-weight: bold;text-align: center;padding: 5px 15px;border: none;font-size: 1.2rem;}
  #overlay .btn_blue {  color: white;  background: rgb(30, 187, 226);}
  @keyframes fadeInAnime {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  /* レスポンシブ設定 */
  @media(max-width:800px){
    .modal-content{width: 90%;}
    .modal-main .content input {font-size: 17px;}
  }
</style>
