<template>
  <section id="tab_block">
    <div class="tabs">
      <div 
        v-for="(label_data,index) in block_data.tab_index_block.labels" 
        class="tab"
        :class="{ active: block_data.tab_index_block.index === label_data.index }" 
        @click="tab_control(label_data.index)" 
        :key="'tab_index_' + index"
      >
        {{ $t(label_data.label)}}
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    props: ["block_data"],
    data() {
      return {
      };
    },
    created() {
    },
    methods: {
      tab_control(index) {
        this.block_data.tab_index_block.index = index
      },
    },
  };
  </script>

  <style>
    #tab_block .tabs {display: flex;}
    #tab_block .tab.active {background-color: white;}
    #tab_block .tab {cursor: pointer;padding: 25px;background-color: #ccc;border-radius: 10px 10px 0 0;}
  </style>
  