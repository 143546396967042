<template>
  <!-- 確認のモーダル -->
  <div id="overlay" v-if="list_items.modal_condition" class="info_modal">
    <div class="modal-content fadeIn">
      <div class="modal-headline">
        <button @click="modal_control(0)">×</button>
      </div>
      <div class="modal-main">
        <div class="content">
          <h1>ご利用前に必ずご確認ください</h1>
          <img src="/img/scratch_info.png">
          <p>● ボタンを押すと、PayPay決済画面に遷移します</p>
          <p>● 利用規約をご確認の上、ご利用ください</p>
          <p>● PayPay決済が完了するとスクラッチが表示されます。</p>
          <p style="font-size: 1.1rem; color: red;">
            ● 返品等(キャンセル)は、デジタルコンテンツの特性上、購入確定後の返品・交換には応じられません。
          </p>
          <p style="font-size: 1.1rem; color: red;">
            ※ 決済完了後　ページが表示されない場合、ページの再読み込みを実施してださい。<br>
            再読み込み後も表示が変わらない場合、管理者へご連絡ください
          </p>
          <div class="point_view">
            <p>購入金額: {{list_items.price}}円</p>
          </div>

          <p style="text-align: center;margin-bottom: 0;">
            <a target="_blank" rel="noopener noreferrer" @click="link_to('CustomerPolicyPage')">プライバシーポリシー</a>
          </p>
          <p style="text-align: center;margin-bottom: 0;margin-top: 0;">
            <a target="_blank" rel="noopener noreferrer" @click="link_to('CustomerTOSPage')">利用規約</a>
          </p>
          <p style="text-align: center;margin-top: 0;">
            <a target="_blank" rel="noopener noreferrer" @click="link_to('CustomerTokushoPage')">「特定商取引に関する法律」に基づく表示</a>
          </p>
          <div class="button_box">
            <button @click="emit_event(1)" class="btn_blue">PayPayで決済</button>
          </div>
          <!-- <div class="button_box">
            <button @click="emit_event(3)" class="btn_blue">テスト環境用</button>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["list_items"],
  data() {
    return {
      error_message:""
    };
  },
  // created() {
  // },
  methods: {
    // 親コンポーネントのメソッドを呼び出す
    emit_event(payment_type){
      this.$emit("onClick",payment_type);
    },
    // 親のmodal_conditionを操作して表示を切り替える
    modal_control(condition) {
      this.list_items.modal_condition = condition
    },
    link_to(page_name){
      let query_parm = {
            sid:this.$route.query.sid,
        }
      this.$router.push({name:page_name,query:query_parm});
    },
  },
};
</script>

<style>
  /* モーダル */
  .info_modal .modal-content {
    position: relative;
    z-index: 2;
    padding: 0;
    overflow: hidden;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    max-height: 80vh;
    overflow-y: scroll;
    border-radius: 0;
    width: 90%;
    border: #489FB9 solid 1px;
    background-color: #F2F1F2;
  }
  #overlay .modal-content button {
    height: auto;
    background-color: #F2F1F2;
    color: #489FB9;
    font-weight: bold;
    text-align: center;
    border: none;
    font-size: 2rem;
    padding: 0;
  }
  .info_modal .modal-main .content {
    display: block;
    margin: 20px auto;
    width: 90%;
    justify-content: end;
    border: none;
  }
  .info_modal .modal-main .content img{
    width: 100%;
  }
  .info_modal .modal-main .content p{
    margin: 20px auto;
    line-height: 1.5rem;
  }
  .info_modal .modal-main .content h1{
    font-size: 1.2rem;
    text-align: center;
    color: #489FB9;
  }
  .info_modal .modal-main .content a{
    color: rgba(var(--bs-link-color-rgb),var(--bs-link-opacity,1));
    text-decoration: underline;
  }
  #overlay .modal-content .modal-main .button_box {
    width: 90%;
  }
  #overlay .modal-content .modal-main .button_box button {
    color:#F2F1F2;
    background-color: #489FB9;
    height: 60px;
    font-weight: bold;
    text-align: center;
    padding: 5px 15px;
    border: none;
    font-weight: unset;
    font-size: 1rem;
  }
  #overlay .modal-content .modal-main .button_box .btn_blue {
    color:#F2F1F2;
    background-color: #489FB9
  }
  #overlay .modal-content .modal-main .button_box .btn_gray {
    background-color:#F2F1F2;
    color: #489FB9
  }

  .info_modal .modal-main .content .point_view p{
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
    color: blue;
    margin: 0 auto;
  }
  .info_modal .modal-main .content .point_view{
    margin: 20px auto;
  }

  @media (min-width: 1200px){
    .info_modal .modal-content {
      width: 45%;
    }
  }
</style>
