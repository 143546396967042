<template>
  <div class="image_block" id="thanks">
      <div class="image_box">
        <img id="bg_image" :src="$commonFunction.return_s3_path(scratch_data.bg_image)">
        <div class="container" id="policy" v-if="this.$route.query.page_type == 1">
          <p>賞品を使用しました</p>
          <p>こちらの画面を担当者にお見せください</p>
          <p>コード: {{$route.query.code}}</p>
          <div class="button_box" :style="{ borderColor: scratch_data.color }">
            <button @click="link_to" :style="{ backgroundColor: scratch_data.color }">Topに戻る</button>
          </div>
        </div>
      </div>
  </div>
  <CustomerFooterBlock :template_id="scratch_data.template_id" class="print_ignore"></CustomerFooterBlock>
</template>
  
<script>
  import CustomerFooterBlock from "@/components/blocks/custom/CustomerFooterBlock";
  export default {
      components: {
          CustomerFooterBlock,
      },
      data() {
        return {
          scratch_data:[]
        }
      },
      created () {
        this.process()
      },
      methods: {
        async process(){
            // くじの情報を取得する
            let response = await this.$commonFunction.axios_get(`customer/scratch/detail/${this.$route.query.sid}`)
            if(response.status === 200){
                this.scratch_data = response.data.item_data[0]
            } else if (response.status == 500){
                this.$router.push({name:"ServerErrorPage"});
            }
        },
        link_to(){
          let query_parm = {
            sid:this.$route.query.sid,
          }
          this.$router.push({name:'CustomerTopPage', query:query_parm});
        }
      }
  };
  </script>

<style>
  #thanks .image_box .container{width: 370px;height: 800px;display: block;position: absolute;top: 130%;left: 50%;transform: translate(-50%, -50%);}
  #thanks .image_box .container .button_box{position: relative;top:10%}
  #thanks .container h2 {color: black;font-weight: bold;font-size: 1.5rem;text-align: center;}
  #thanks .image_box .container p{position: relative;text-align: center;margin-top: 20px;top:0}
</style>
  
  