<template>
  <div class="image_block" id="server_error">
      <div class="image_box">
        <img id="bg_image" src="/img/scratch_bg1.png">
          <div id="temp_logo_box">
              <img src="/img/temp_logo1.png">
          </div>
          <div class="container" id="policy" style="margin-top: 120px;">
              <p>このくじは無効です。</p>
              <p>URLを確認してください</p>
          </div>
      </div>
  </div>
</template>
