<template>
    <LoadingBlock v-model="block_data.loading_block" v-bind:loading_block="block_data.loading_block"></LoadingBlock>
    <div v-show="!block_data.loading_block.is_loading">
      <SampleHeaderBlock></SampleHeaderBlock>
      <div class="container">
        <PageGuideBlock :block_data="block_data"></PageGuideBlock>
        <div class="detail_block">
            <section id="scratch_block">
                <div class="input_block">
                    <p>登録日時</p>
                    <p class="data_value">{{scratch_detail.created}}</p>
                </div>
                <div class="input_block">
                    <p>タイトル</p>
                    <p class="data_value">{{scratch_detail.title}}</p>
                </div>
                <div class="input_block">
                    <p>５等の扱い</p>
                    <p v-if="scratch_detail.miss_prize_type == 0" class="data_value">景品なし</p>
                    <p v-if="scratch_detail.miss_prize_type == 1" class="data_value">景品あり</p>
                </div>
                <!-- <div class="input_block">
                    <p>リセット</p>
                    <p v-if="scratch_detail.reset_status == 1" class="data_value">1日に一回</p>
                    <p v-if="scratch_detail.reset_status == 2" class="data_value">なし(1回限り)</p>
                </div>
                <div class="input_block">
                    <p>オプション</p>
                    <p v-if="scratch_detail.charged_option == 0" class="data_value">無料</p>
                    <p v-if="scratch_detail.charged_option == 1" class="data_value">有料</p>
                </div> -->
                <div class="input_block">
                    <p>有効期間</p>
                    <p class="data_value">{{scratch_detail.period}}</p>
                </div>

                <div class="input_block" id="qr_image">
                    <qrcode-vue3 
                        :width="150"
                        :height="150"
                        :value="qr_url"
                        :dotsOptions="{type: 'square',color: '#000000'}"
                        :cornersSquareOptions="{ type: 'square', color: '#000000' }"
                        :cornersDotOptions="{ type: 'square', color: '#000000' }"
                    />
                    <div class="qr_text_box">
                        <input v-model="qr_url" readonly="readonly"></input>
                        <div style="float: right;">
                            <button class="qr_button" @click="download_image">ダウンロード</button>
                        </div>
                    </div>
                </div>
                <div>
                    <h2>当選状況</h2>
                    <p style="white-space: break-spaces;">{{scratch_detail.preview}}</p>
                </div>
            </section>
            <section style="height: 300px;width: 30%;margin: 20px auto;">
                <img style="height: 300px;width: 250px;" :src="$commonFunction.return_s3_path(scratch_detail.bg_image)">
            </section>

        </div>
        <section id="search_block_list">
            <div class="search_button button_box">
                <p>賞品検索</p>
                <input type="text" name="search_word" placeholder="キーワード" v-model="block_data.tab_content_block.list_questions.list_search_property.search_word">
                <button class="btn_search" @click="start_search">検索</button>
                <button class="btn_search_reset" @click="reset_search">リセット</button>
            </div>
        </section>
        <!-- PCリスト -->
        <PCTableParts :list_items="block_data.tab_content_block.list_questions"></PCTableParts>
        <div id="overlay" v-if="block_data.tab_content_block.list_questions.modal_condition != 0">
            <AddModalParts :list_items="block_data.tab_content_block.list_questions"></AddModalParts>
            <SelectModalParts :list_items="block_data.tab_content_block.list_questions"></SelectModalParts>
        </div>
      </div>
    </div>
    <FooterBlock></FooterBlock>
  </template>
  
<script>
import PCTableParts from "@/components/blocks/default/PCTableParts";
import AddModalParts from "@/components/blocks/default/AddModalParts";
import SelectModalParts from "@/components/blocks/default/SelectModalParts";
import QrcodeVue3 from 'qrcode-vue3';
  export default {
    components: {
        PCTableParts,
        AddModalParts,
        SelectModalParts,
        'qrcode-vue3': QrcodeVue3
    },
      data() {
        return {
            s3_path:"https://s3-ap-northeast-1.amazonaws.com/sasaeru-test/",
            qr_url:"",
            scratch_detail:[],
          block_data:{
              query_param:{},
              loading_block:{
                  is_loading:false
              },
              return_block:{
                  return_page_name:"UserTopPage",
                  return_page_is_query:true,
                  page_guides:[
                      {
                          name:"PageNameScratchDetail",
                          link:"",
                          is_query:false,
                      },
                  ],
              },
              tab_index_block:{
                  index:1,
                  labels:[
                      {index:1,label:"PageNameScratchDetail"},
                  ],
              },
              tab_content_block:{
                  list_questions:{
                      modal_condition:0,
                      list_search_property:{
                          label:"PageNameScratchDetail",
                          source_list:[],
                          source_list_default:[],
                          is_search:false,
                          search_word: "",
                      },
                      add_menu_button_block: [
                          {
                              label: "CommonRegister",
                              method_type: "add",
                              link: "",
                          },
                      ],
                      current_page:1,
                      per_page:5,
                      detail_page_name:"UserScratchViewPage",
                      detail_key:"scratch_no",
                      detail_key_label:"s_no",
                      post_add_url:"/user/scratch/number/add",
                      post_delete_url:"/user/scratch/number/delete",
                      select_data:{},
                      item_name:"PageNameScratchDetail",
                      list_buttons:[],
                      item_data:[],
                      item_data_default:[],
                      item_models:[
                          {
                              label:"LabelID",
                              model_name:"id",
                              type:"text",
                              value:"",
                              add_modal_visible:false,
                              list_visible:false,
                              editable:false,
                          },
                          {
                              label:"LabelID",
                              model_name:"scratch_id",
                              type:"text",
                              value:"",
                              add_modal_visible:false,
                              list_visible:false,
                              editable:false,
                          },
                          {
                              label:"LabelPrizeLabel",
                              model_name:"prize_code",
                              type:"text",
                              value:"",
                              add_modal_visible:true,
                              list_visible:true,
                              editable:false,
                          },
                          {
                              label:"LabelPrizeID",
                              model_name:"scratch_code",
                              type:"text",
                              value:"",
                              add_modal_visible:true,
                              list_visible:true,
                              editable:false,
                          },
                          {
                              label:"LabelScratchUUId",
                              model_name:"customer_uuid",
                              type:"text",
                              value:"",
                              add_modal_visible:true,
                              list_visible:true,
                              editable:false,
                          },
                          {
                              label:"LabelScratchIsUsed",
                              model_name:"is_used",
                              type:"text",
                              value:"",
                              add_modal_visible:true,
                              list_visible:true,
                              editable:false,
                          },
                          {
                              label:"LabelScratchCreateDate",
                              model_name:"created",
                              type:"date",
                              value:"",
                              add_modal_visible:false,
                              list_visible:true,
                              editable:false,
                          },
                      ]
                  }  
              }
          }
        }
      },
      created () {
          this.process()
      },
      methods: {
          async process() {
            this.block_data.loading_block.is_loading = true

            this.qr_url = `https://scratch-app.jp/customer/top?sid=${this.$route.query.s_id}`
            //    スクラッチ情報取得
            let response = await this.$commonFunction.axios_get(`user/scratch/detail/${this.$route.query.s_id}`)
            if(response.status == 200){
                let detail_data = response.data.item_data[0]
                this.scratch_detail = detail_data
                let preview_text = `1等(${detail_data["first_prize_label"]}) :  ${detail_data["1p_start_count"] - detail_data["1p_current_count"]}/${detail_data["1p_start_count"]}\n`
                preview_text += `2等(${detail_data["second_prize_label"]}):  ${detail_data["2p_start_count"] - detail_data["2p_current_count"]}/${detail_data["2p_start_count"]}\n`
                preview_text += `3等(${detail_data["third_prize_label"]}):  ${detail_data["3p_start_count"] - detail_data["3p_current_count"]}/${detail_data["3p_start_count"]}\n`
                preview_text += `4等(${detail_data["fourth_prize_label"]}):  ${detail_data["4p_start_count"] - detail_data["4p_current_count"]}/${detail_data["4p_start_count"]}\n`
                preview_text += `5等(${detail_data["fifth_prize_label"]}):  ${detail_data["5p_start_count"] - detail_data["5p_current_count"]}/${detail_data["5p_start_count"]}\n`
                this.scratch_detail["preview"] = preview_text
                this.scratch_detail["created"] = `${this.$commonFunction.date_format(detail_data.created)}`
                    
                this.scratch_detail["period"] = `${this.$commonFunction.date_format(detail_data.start_date)} ~ ${this.$commonFunction.date_format(detail_data.end_date)}`
                let target_index = this.$commonFunction.return_item_index(this.block_data.tab_content_block.list_questions.item_models,"scratch_id")
                this.block_data.tab_content_block.list_questions.item_models[target_index].value = this.$route.query.s_id

                // 賞品一覧の取得
                response = await this.$commonFunction.axios_get(`user/scratch/prize/list/${this.$route.query.s_id}`)
                this.block_data.tab_content_block.list_questions.item_data = response.data.item_data
                this.block_data.tab_content_block.list_questions.item_data_default = response.data.item_data
                this.block_data.loading_block.is_loading = false
            } else if (response.status == 401){
                this.$router.push({name:"UserLoginPage"});
            } else if (response.status == 500){
                this.$router.push({name:"ServerErrorPage"});
            }     
            
            
        },
        // QR画像をダウンロード
          download_image(){
            var imgElement = document.querySelector('#qr_image img');

            var downloadLink = document.createElement('a');
            downloadLink.href = imgElement.src;
            var fileName = imgElement.src.substring(imgElement.src.lastIndexOf('/') + 1);
            downloadLink.download = "QRコード.png";
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink); 
          },
        // モーダル
        modal_control(){
            this.block_data.tab_content_block.list_questions.modal_condition= 1
        },
        // 検索処理
        start_search(){
            let source_items = []
            let new_items = []
            this.block_data.tab_content_block.list_questions.list_search_property.is_search = true
            source_items = this.block_data.tab_content_block.list_questions.item_data_default
            // 検索ワードでフィルタ
            if (this.search_word != ""){
                new_items = []
                for (const item of source_items) {
                if(item.scratch_no.includes(this.block_data.tab_content_block.list_questions.list_search_property.search_word)){
                    new_items.push(item)
                }
                }
            } else {
                new_items = source_items
            }
            this.block_data.tab_content_block.list_questions.item_data = new_items
        },
        // 検索条件のリセット
        reset_search(){
            this.block_data.tab_content_block.list_questions.list_search_property.is_search = false
            this.block_data.tab_content_block.list_questions.item_data = this.block_data.tab_content_block.list_questions.item_data_default
            this.block_data.tab_content_block.list_questions.list_search_property.search_word = ""
        },
      }
  
  };
  </script>
  
  
  <style>
    .table_pc thead th {background: #F1F9FF;text-align: center;}
    .table_pc tbody td p{text-align: center;}
    .table_pc tbody td p button{width: 70%;color: white;background-color: #2699FB;border: none;padding: 10px 20px;border-radius: 5px;}

    .detail_block{width: 70%;margin: 10px auto;display: flex;justify-content: space-between;}
    .detail_block #scratch_block{width: 65%;margin: 0;}
    .detail_block #scratch_block img{width: 100%;}
    .detail_block .input_block input {border: 1px solid gray;}

    .detail_block .qr_text_box input{width: 100%;background: white;color: black;padding: 10px;}
    .qr_text_box {width: 100%;}
    .qr_button{color: white;background: #2699FB;font-weight: bold;margin: 10px;height: 50px;border-radius: 5px;border: none;}

    .input_block .data_value{
        border: 1px solid gray;
    padding-left: 10px;
    width: 80%;
    background-color: white;
    color: black;
    font-weight: unset;
    text-align: left;
    }

</style>