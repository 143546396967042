<template>
  <div class="image_block" id="tos_page">
      <div class="image_box" id="tos_height">
        <div class="container">
          <h2>- サービスについて -</h2>
          <p>注意事項</p>
          <p>サービスの表示には通信回線が必要になります。通信ができない環境では表示が出来ません。また、通信が不安定な状態では、表示が不完全になる可能性があります。</p>
          <p>使用許諾</p>
          <p>アプリをご利用頂くにあたり、必ず下記のアプリサービス利用規約をお読みください。ご利用頂く場合は、使用許諾に同意頂いたものとみなします。</p>
          <h2>- WIN-WINスクラッチ 利用規約 -</h2>
          <h3>第1条 目的</h3>
          <p>
            WIN-WINスクラッチ 利用規約（以下「本規約」という。）は、WIN-WINスクラッチ を通じて提供されるサービス（以下「本サービス」という。）の利用に際しての規約です。本サービスをご利用の際には、本規約に従っていただくようお願いいたします。
          </p>
          <h3>第2条 適用範囲</h3>
          <p>本規約は、ササエルデザイン株式会社（以下「当社」という。）が提供する本サービスを利用者が利用する場合に適用されます。</p>
          <h3>第3条 利用契約</h3>
          <p>本サービスのご利用をもって本規約の内容を承諾したものとみなし、当社との利用契約（以下「利用契約」という。）が成立するものとします。</p>
          <h3>第4条 サービスの提供条件</h3>
          <p>本サービスの利用に当たって、利用者は次の条件をいずれも満たしていることを前提とします。
            本サービスを利用いただくために必要となるインターネットを利用できる環境を有したスマートフォンを所有していること。
            なお、スマートフォン機種毎の動作検証又は動作保証はいたしません。
            日本国内に居住しており、日本語を用いて本サービスの提供を受けることができること。
          </p>
          <h3>第5条 サービス内容</h3>
          <p>
            当社は、お客様に対し、次に掲げるサービスを提供します。<br>
            PayPay Webペイメントでの事前決済<br>
            当社のスクラッチ結果
          </p>
          <h3>第6条 利用期間及び利用料</h3>
          <p>
            本サービスの利用期間（以下「利用期間」という。）は、利用契約が成立した日（以下「利用開始日」という。）から第9条により当社が提供を停止するまでとします。
          </p>
          <h3>第7条 利用者の遵守事項</h3>
          <p>
            １　利用者は、利用者による本サービスの利用と本サービスを利用してなされた一切の行為に起因して、当社又は第三者に対して損害を与えた場合（利用者が本規約上の義務を履行しないことにより当社又は第三者が損害を被った場合を含みます。）、自己の責任と費用をもって損害を賠償するものとします。<br>
            ２　 利用者は、本サービスを利用して入手したプログラムに対し、逆コンパイル又は逆アセンブルを行わないものとします。また、著作権侵害防止のための技術的保護手段の施されたデータ等に対し、当該手段の回避を行わないものとします。<br>
            ３　本サービスを利用する際に要する通信費等は、利用者が負担するものとします。
          </p>
          <h3>第8条 サポート</h3>
          <p>
            利用者からの本サービスの問い合わせについては、当社を窓口とします。
          </p>
          <h3>第9条 サービスの利用停止</h3>
          <p>
            1　利用者が、本サービスを削除した場合は、本サービスの利用資格も同時に喪失するものとします。<br>
            ２　 利用者が次の各号のいずれかに該当する場合、当社は、当該利用者に対して本サービスの全部または一部の利用を停止する場合があります。<br>
            本規約に違反した場合<br>
            本サービスの運営を妨げる行為を行った場合<br>
            当社又は第三者の著作権及びその他知的財産権を侵害する行為を行った場合<br>
            当社又は第三者の財産、名誉、信用、プライバシー等を侵害する行為を行った場合<br>
            当社又は第三者に不利益若しくは損害を与える行為を行った場合<br>
            犯罪行為又は犯罪行為に結びつく行為を行った場合<br>
            法令、条例又は公序良俗に反する行為を行った場合<br>
            その他当社が不適切と判断した行為を行った場合<br>
            ３　前項各号のいずれかの行為を利用者が行ったことにより当社が損害を被った場合、当社は利用者に対し損害の賠償を請求することがあります。
          </p>
          <h3>第10条 サービスの中断・終了</h3>
          <p>
            １　利用者は、次の各号に定める場合に、当社が本サービスの提供を中断することがあることを了承するものとします。<br>
            天災地変、労働争議等の不可抗力により本サービスの提供ができない場合<br>
            本サービス提供用の設備の保守上又は工事上やむをえない場合<br>
            運用上又は技術上、本サービスの一時的な中断を必要とした場合<br>
            ２　当社は、利用者への事前の通知により、本サービスの提供の全部又は一部を終了する場合があることを利用者は了承するものとします。
          </p>
          <h3>第11条 反社会的勢力等の排除</h3>
          <p>
            1 　利用者は、自らが次の各号に掲げる者（以下、「反社会的勢力等」という）に該当せず今後も該当しないこと、また、反社会的勢力等との関係を持っておらず今後も持たないことを確約します。<br>
            警察庁「組織犯罪対策要綱」記載の「暴力団、暴力団員、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロ、特殊知能暴力集団等」その他これらに準ずる者<br>
            資金や便宜を供与したり、不正の利益を図る目的で利用するなど、前号に記載する者と人的・資本的・経済的に深い関係にある者<br>
            ２　利用者は、自ら又は第三者を利用して、次の各号に記載する行為を行わないことを確約します。<br>
            詐術、暴力的行為又は脅迫的言辞を用いる行為<br>
            違法行為又は不当要求行為<br>
            業務を妨害する行為<br>
            名誉や信用等を毀損する行為<br>
            前各号に準ずる行為<br>
            ３　当社は、利用者が前各項に違反したときは、利用者に対して損害賠償義務を負うことなく、何等の催告なしに直ちに本サービスの全部又は一部の利用を停止することがあります。<br>
          </p>
          <h3>第12条 変更</h3>
          <p>
            本規約における当社から利用者への通知は、専用ホームページでの表示により行われるものとします。
          </p>
          <h3>第13条 通知</h3>
          <p>
            本規約における当社から利用者への通知は、専用ホームページでの表示により行われるものとします。
          </p>
          <h3>第14条 準拠法・管轄裁判所</h3>
          <p>
            １　本規約は、日本法に準拠し解釈されるものとします。<br>
            ２　本市と利用者の間で、本サービス又は本規約に関して紛争が生じた場合には、相互が満足できる解決を図るため誠実に対応することとします。<br>
            ３　前項により解決がなされず訴訟を提起する場合、当社の本店所在地を管轄する裁判所を専属的合意管轄裁判所とします。
          </p>
          <h3>附　則</h3>
          <p>
            この規約は、令和6年8月1日から施行します。<br>
            愛知県名古屋市中区 栄三丁目18番1号 デザインセンタービル7階 デザインラボ5号室 <br>ササエルデザイン株式会社
          </p>



          
          
          <div class="button_box">
            <button @click="link_to" style="background-color: rgb(98, 239, 222);">
                TOPに戻る
            </button>
            <p class="error_message">{{error_message}}</p>
          </div>
        </div>
      </div>
  </div>
  <FooterBlock></FooterBlock>
</template>
  
<script>
  export default {
    components: {
    },
      data() {
        return {
          scratch_data:[]
        }
      },
      created () {
      },
      methods: {
        link_to(){
          if(this.$route.query.sid){
            let query_parm = {
              sid:this.$route.query.sid,
            }
            this.$router.push({name:'CustomerTopPage', query:query_parm});
            } else {
            this.$router.push({name:'SampleTopPage'});
          }
        }
      }
  };
  </script>

<style>
  #tos_page .image_box .container{background-color: white;width: 95%;height:100%;margin: 0 auto;display: block;position: absolute;top: 0;}
  #tos_page h2{text-align: center;font-size: 1.2rem;font-weight: bold;margin: 50px auto 20px auto;}
  #tos_page h3{font-weight: bold;border-bottom: solid 0.1px;padding-bottom: 10px;padding-top: 10px;}
  #tos_page p {font-size: 0.8rem;padding-top: 10px;line-height: 18px;padding-bottom: 10px;}
  #tos_page .image_box {background-color: rgb(98, 239, 222);height: 1600px;}

  #tos_page .image_box .button_box {
    position: relative;
    position: relative;
    margin: 15px auto;
    top:0;
    left: 0;
    transform: none;
  }
  
  @media (min-width: 1201px) {
    #tos_page #tos_height{
      height: 2300px;
    }
  }
  @media (max-width: 1200px) {
    #tos_page #tos_height{
      height: 2300px;
    }
  }
  @media (max-width: 992px) {
    #tos_page #tos_height{
      height: 2550px;
    }
  }
  @media (max-width: 768px) {
    #tos_page #tos_height{
      height: 2750px;
    }
  }
  @media (max-width: 576px) {
    #tos_page .image_box {
      height: 2750px;
    }
  }
  @media (max-width: 530px) {
    #tos_page #tos_height{
      height: 2950px;
    }
  }
  @media (max-width: 460px) {
    #tos_page #tos_height{
      height: 3050px;
    }
  }
  @media (max-width: 420px) {
    #tos_page #tos_height{
      height: 3350px;
    }
  }


</style>