<template>
  <!-- ページネーション -->
  <ul class="Pagination">
    <li class="Pagination-Item">
      <a class="Pagination-Item-Link" @click="prevPage" :disabled="list_items.current_page === 1">
        <svg xmlns="http://www.w3.org/2000/svg" class="Pagination-Item-Link-Icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M11 19l-7-7 7-7m8 14l-7-7 7-7" />
        </svg>
      </a>
    </li>
    <span v-show="startPage > 1">...</span>
    <li class="Pagination-Item" v-for="page in limitedPageNumbers" >
      <a 
        class="Pagination-Item-Link" 
        :key="page" 
        @click="jumpPage(page)" 
        :disabled="page === list_items.current_page"
      ><span>{{ page }}</span></a>
    </li>
    <span v-show="endPage < totalPages">...</span>
    <li class="Pagination-Item">
      <a class="Pagination-Item-Link" @click="nextPage" :disabled="list_items.current_page === totalPages">
          <svg xmlns="http://www.w3.org/2000/svg" class="Pagination-Item-Link-Icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M13 5l7 7-7 7M5 5l7 7-7 7" />
          </svg>
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  props: ["list_items"],
  data() {
    return {
    }
  },
  computed: {
    paginatedData() {
      const start = (this.list_items.current_page - 1) * this.list_items.per_page;
      const end = start + this.list_items.per_page;
      return this.list_items.item_data.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.list_items.item_data.length / this.list_items.per_page);
    },
    startPage() {
      return Math.max(this.list_items.current_page - 1, 1);
    },
    endPage() {
      return Math.min(this.startPage + 2, this.totalPages);
    },
    limitedPageNumbers() {
      return Array.from({ length: this.endPage - this.startPage + 1 }, (_, i) => this.startPage + i);
    },
  },
  methods: {
    nextPage() {
      if (this.list_items.current_page < this.totalPages) this.list_items.current_page = this.totalPages;
    },
    prevPage() {
      if (this.list_items.current_page > 1) this.list_items.current_page = 1;
    },
    jumpPage(page) {
      this.list_items.current_page = page;
    },
  },
};
</script>

<style>
  .Pagination {display: flex;align-items: center;justify-content: center;}
  .Pagination-Item-Link {width: 45px;height: 45px;border-radius: 50%;display: flex;justify-content: center;align-items: center;flex-wrap: wrap;overflow: hidden;background: #fff;border: solid 2px #111;font-size: 14px;color: #111;font-weight: bold;transition: all 0.15s linear;}
  .Pagination-Item-Link-Icon {width: 20px;}
  .Pagination-Item-Link.isActive {background: #111;color: #fff;pointer-events: none;}
  .Pagination-Item-Link:not(.isActive):hover {background: #111;color: #fff;}
  .Pagination > * + * {margin-left: 8px;}
</style>