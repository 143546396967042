<template>
    <div class="image_block" id="server_error">
        <div class="image_box">
          <img id="bg_image" src="/img/scratch_bg1.png">
            <div id="temp_logo_box">
                <img src="/img/temp_logo1.png">
            </div>
            <div class="container" id="policy" style="margin-top: 120px;">
                <p>このページはエラーページです</p>
                <p>ページの再読み込みで、問題が解消される場合があります。</p>
                <p>読み込み直してもエラーが解消されない場合、管理者へご連絡ください。</p>
            </div>
        </div>
    </div>
</template>
  
<style>
    #server_error p{text-align: center;font-size: 1rem; margin: 0.5rem;color: white;}
</style>