<template>
    <table class="table table_pc" border="1" style="border-collapse: collapse" align="center">
      <thead>
        <tr>
          <th 
            class="list_color"
            v-for="(data, index) in list_items.item_models" 
            :key="'list_head_item_' + index"
            v-show="data.list_visible"
          >
            {{ $t(data.label) }}
          </th>
          <th 
            class="list_color"
            v-for="(data, index) in list_items.list_buttons" 
            :key="'list_head_button_' + index" 
          >
            {{ $t(data.label) }}
          </th>
        </tr>
      </thead>
      <!-- データなし -->
      <tbody v-show="list_items.item_data.length == 0">
        <tr class="data_null">
          <td :colspan="get_column_length(list_items)">
            {{ $t("ErrorDataNotFound") }}
          </td>
        </tr>
      </tbody>
      <!-- データあり -->
      <tbody v-show="list_items.item_data.length > 0">
        <tr v-for="(data,index) in list_items.item_data" :key="'list_item_tr_'+ list_items.item_name + index" v-bind:class="{ close: data.is_archive }">
          <td 
            v-for="(label_data, index) in list_items.item_models" 
            :key="'list_item_td_item_' + index" 
            v-show="label_data.list_visible"
          >
            <p v-show="label_data.type == 'text'||label_data.type == 'description'">{{ data[label_data.model_name] }}</p>
            <p v-show="label_data.type == 'price'">{{ Number(data[label_data.model_name]).toLocaleString() }} {{ $t("CommonYen")}}</p>
            <p v-show="label_data.type == 'date'">{{ $commonFunction.date_format(data[label_data.model_name]) }}</p>
          </td>
          <!-- ボタンの表記  -->
          <td 
            v-for="(button_label_data, index) in list_items.list_buttons" 
            :key="'list_item_td_button_' + index"
          >
            <p>
              <div class="button_box">
                <button v-show="button_label_data.type == 'detail'" @click="link_detail(data)" class="btn_navy">
                  {{ $t("CommonDetail")}}
                </button>
                <button v-show="button_label_data.type == 'edit'" @click="data_select(data,2)" class="btn_cyan">
                  {{ $t("CommonEdit")}}
                </button>
                <button v-show="button_label_data.type == 'delete'" @click="data_select(data,3)" class="btn_silver">
                  {{ $t("CommonDelete")}}
                </button>
              </div>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
</template>

<script>
export default {
  props: ["list_items"],
  data() {
    return {
    }
  },
  computed: {
    paginatedData() {
      const start = (this.list_items.current_page - 1) * this.list_items.per_page;
      const end = start + this.list_items.per_page;
      return this.list_items.item_data.slice(start, end);
    }
  },
  methods: {
    // カラム数カウント
    get_column_length(list_items){
      let count = 0
      for (const data of list_items.item_data) {
        if(data.add_modal_visible){
          count += 1
        }
      }
      count += list_items.item_data.length
      return count
    },
    // リンク先を設定
    link_detail(data){
      let query_param = this.$route.query
      query_param[this.list_items.detail_key_label] = data[this.list_items.detail_key]
      this.$router.push({
        name:this.list_items.detail_page_name, 
        query: query_param
      });
    },
    // 親のセレクトデータに値を代入する
    data_select(select_data,condition) {
      this.list_items.modal_condition = condition
      this.list_items.select_data = select_data
    },
  },
};
</script>

<style>
  #list_block {background: white;padding: 20px;border-radius: 8px;}
  .btn_search {color: white;background: #2B68BA;}
  .btn_search_reset {color: white;background: #9A9A9A;}
  #list_block thead tr th {text-align: center;color: #2B68BA;background-color: #EDEDED;}
  #list_block td p{text-align: center;}
  #list_block .button_box button {margin: 0 auto;cursor: pointer;width: 90%;border: none;text-align: center;display: inline-block;padding: 0.5em 1em;text-decoration: none;border-radius: 15px;}
  .btn_cyan {color: white;background: #2B68BA;}
  .btn_navy {color: white;background: #1B328D;}
</style>