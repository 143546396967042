<template>
    <LoadingBlock v-model="block_data.loading_block" v-bind:loading_block="block_data.loading_block"></LoadingBlock>
    <AdminHeaderBlock></AdminHeaderBlock>
    <div class="container">
        <PageGuideBlock :block_data="block_data"></PageGuideBlock>
        <LinkMenuBlock :link_menu_block="block_data.link_menu_block"></LinkMenuBlock>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                block_data:{
                    query_param:{
                    },
                    loading_block:{
                        is_loading:false
                    },
                    return_block:{
                        return_page_name:"",
                        return_page_is_query:true,
                        page_guides:[
                            {
                                name:"PageNameTop",
                                link:"",
                                is_query:false,
                            },
                        ],
                    },
                    link_menu_block:[
                        {
                            title_label:"PageNameAdminUserList",
                            description_label:"CommonDummyData",
                            link_page_name:"AdminUserListPage",
                            image:"/img/icon-relation.png",
                        },
                        {
                            title_label:"PageNameAdminPlan",
                            description_label:"CommonDummyData",
                            link_page_name:"AdminPlanListPage",
                            image:"/img/icon-file.png",
                        },
                        {
                            title_label:"PageNameAdminSetting",
                            description_label:"CommonDummyData",
                            link_page_name:"SampleSettingPage",
                            image:"/img/icon-setting.png",
                        },
                        {
                            title_label:"PageNameAdminDesign",
                            description_label:"CommonDummyData",
                            link_page_name:"AdminDesignPage",
                            image:"/img/icon-prize.png",
                        },
                    ]
                }
            }
        },
        // created(){
        //     console.log(this.$commonFunction)
        //     this.$commonFunction.test_aaaa();
        // }
    };
</script>

