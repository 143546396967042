<template>
  <LoadingBlock v-model="block_data.loading_block" v-bind:loading_block="block_data.loading_block"></LoadingBlock>
  <div v-show="!block_data.loading_block.is_loading">
    <div class="container">
      <section id="login_block">
        <div id="custom_login_block">
          <h2>Login</h2>
          <h1>WIN-WIN SCRATCH</h1>
          <div class="input_section">
            <input :placeholder="$t('PlaceholderUsername')" type="mail" name="username" v-model='post_data.username'>
            <input :placeholder="$t('PlaceholderPassword')" name="password" type="password" v-model='post_data.password'>  
          </div>
          <div class="button_box">
            <button class="btn_blue" @click="login">{{ $t("CommonLogin")}}</button>
          </div>
          <div v-if="page_data.is_error">
            <p class="error_message_color">{{$t(page_data.is_error)}}</p>  
          </div>
          <!-- <p><router-link :to="{name:page_data.pass_reset_page_name}">{{$t("CommonPassReset")}}</router-link></p> -->
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Cookies from 'js-cookie';
export default {
  data () {
    return {
      page_data:{
        pass_reset_page_name:"",
        is_error:""
      },
      block_data:{
        loading_block:{
          is_loading:false
        },
        return_block:{
          return_page_name:"SampleTopPage",
          return_page_is_query:true,
          page_guides:[
            {
                name:"PageNameTop",
                link:"SampleTopPage",
                is_query:false,
            },
            {
                name:"PageNameSampleLogin",
                link:"",
                is_query:false,
            },
          ],
        },
      },
      post_data:{
        username: "",
        password: "",
        email: "aaa@co.jp",
        user_type: 1,
      }
    }
  },
  methods: {
    async login () {
      this.block_data.loading_block.is_loading = true
      let response = await this.$commonFunction.axios_post('authorization/login', this.post_data)
      if (response.status === 200) {
        Cookies.set('user_id', response.data.user_id);
        Cookies.set('user_type', response.data.user_type);
        Cookies.set('user_name', response.data.user_name);
        this.$router.push({name:"UserTopPage"})
      } else {
        this.page_data.is_error = "ErrorLoginFailed"
        this.block_data.loading_block.is_loading = false
      }
    },
  }
}
</script>

<style>
  #login_block{margin: 20px auto;padding: 20px;border-radius: 15px;}
  #login_block p{text-align: center;}
  #login_block .image_box{margin: 20px auto;text-align: center;}
  #login_block .input_section {display: grid;}
  #login_block .input_section input {width: 40%;margin: 10px auto;padding: 10px;}
  #login_block .button_box {margin: 20px auto;width: 20%;text-align: center;}
  #login_block .button_box button{width: 100%;border: none;text-align: center;display: inline-block;padding: 0.5em 1em;text-decoration: none;border-radius: 15px;}
  #login_block .button_box .btn_blue {color: white;background: rgb(30, 187, 226);font-weight: bold;font-size: 1.2rem;}
  #login_block p {margin-top: 0;margin-bottom: 1rem;}
  #login_block img {vertical-align: middle;}

  #custom_login_block{
    margin: 150px auto 20px auto;
    text-align: center;
  }
  #custom_login_block h1{
    font-size: 2rem;
  }
  #custom_login_block h2{
    font-size: 1.5rem;
  }
  #custom_login_block .input_section{
    margin-top: 20px;
  }
  #custom_login_block .input_section input {
    border-radius: 10px;
    background: #F0F0F0;
    color: #2699FB;
  }

  @media (max-width: 800px){
    #login_block .button_box {width: 50%;}
    #login_block .input_section input {width: 80%;}
  }
  
</style>