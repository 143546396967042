<template>
  <section id="top_link_menu">
    <div class="flex_box">
      <div class="button_box" v-for="data in link_menu_block" :key="data.id" @click="link_page(data)">
        <div class="img_area">
          <img :src="data.image" />
        </div>
        <div class="title_area">
          <h2>{{$t(data.title_label)}}</h2>
        </div>
        <div class="detail_area">
          <p>{{$t(data.description_label)}}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ["link_menu_block"],
  data() {
    return {
    }
  },
  methods: {
    // ページに遷移
    async link_page(data) {
      this.$router.push({
        name:data.link_page_name,
      });
    },
  },
};
</script>

<style>
  #top_link_menu {
    margin: 20px auto;
    background-color: white;
    padding: 20px;
    border-radius: 15px;
  }
  #top_link_menu .flex_box {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
  #top_link_menu .button_box {
    width: calc(50% - 100px);
    background-color: #2B68BA;
    color: white;
    margin: 30px 10px;
    box-sizing: border-box;
    border-radius: 12px;
    text-align: center;
    padding: 20px;
  }
  #top_link_menu .button_box .img_area  {
    height: 100px;
  }
  #top_link_menu .button_box .img_area img {
    padding: 20px;
    vertical-align: middle;
  }
  #top_link_menu .button_box .title_area h2 {
    font-size: 2rem;
  }
  #top_link_menu .button_box .detail_area p {
    font-size: 1rem;
  }
  @media (max-width: 800px){
    #top_link_menu .flex_box {
      display: block;
    }
    #top_link_menu .button_box {
      width: 100%;
      margin: 10px auto;
    }
  }

</style>