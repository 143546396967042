<template>
  <HeaderBlock :header_block="block_data.header_block"></HeaderBlock>
</template>

<script>
export default {
    data() {
        return {
          block_data:{
            header_block: {
              link_items: [
              ],
            },
          }
        };
    },
};
</script>

