<template>
  <section id="menu_button">
    <div
      class="button_box"
      v-for="data in list_items.add_menu_button_block"
      :key="data.id"
    >
      <button
        class="btn_blue"
        v-bind:class="[data.method_type == 'add' ? 'new_button' : '']"
        @click="button_method(data)"
      >
        {{ $t(data.label) }}
      </button>
    </div>
  </section>
</template>

<script>
export default {
  props: ["list_items"],
  data() {
    return {
    };
  },
  methods: {
    // 親コンポーネントからの入力に応じて処理を切り替える
    button_method(data) {
      if (data.method_type == "add") {
        this.list_items.modal_condition = 1
      } else if (data.method_type == "link") {
        this.$router.push({name:data.link});
      }
    },
  },
};
</script>

<style>
  #menu_button{display: flex;justify-content: center;margin: 20px auto;}
  #menu_button .button_box {margin: 0 10px;width: 20%;text-align: center;}
  #menu_button .button_box button {height: 60px;cursor: pointer;width: 100%;border: none;text-align: center;display: inline-block;padding: 0.5em 1em;text-decoration: none;border-radius: 5px;font-weight: bold;}
  #menu_button .button_box .new_button {background-color: white;color: #2B68BA;}
  #menu_button .button_box .new_button:before {content: " + ";font-size: 1.5rem;}
  #menu_button .button_box .btn_blue{color: white;background: #FB26B9;}
  @media (max-width: 800px){
    #menu_button .button_box {width: 50%;text-align: center;}
    #menu_button .button_box button {padding: 0.5em 0.3em;}
  }

</style>
