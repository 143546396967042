<template>
  <LoadingBlock v-model="block_data.loading_block" v-bind:loading_block="block_data.loading_block"></LoadingBlock>
  <div v-show="!block_data.loading_block.is_loading">
    <SampleHeaderBlock></SampleHeaderBlock>
    <div class="container">
      <PageGuideBlock :block_data="block_data"></PageGuideBlock>
      <SettingBlock :block_data="block_data"></SettingBlock>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
    data() {
      return {
        block_data:{
            query_param:{},
            loading_block:{
                is_loading:false
            },
            return_block:{
                return_page_name:"SampleTopPage",
                return_page_is_query:true,
                page_guides:[
                    {
                        name:"PageNameTop",
                        link:"SampleTopPage",
                        is_query:false,
                    },
                    {
                        name:"PageNameSampleSetting",
                        link:"",
                        is_query:false,
                    },
                ],
            },
            setting_block:{
                get_url:"/user/customer/edit",
                post_url:"/user/customer/edit",
                item_name:"PageNameSampleList",
                item_data:[
                    {id:1,name:"aaaaa"},
                    {id:2,name:"bbbb"},
                    {id:3,name:"cccc"}
                ],
                item_models:[
                    {
                        label:"LabelID",
                        model_name:"id",
                        type:"text",
                        value:"",
                        add_modal_visible:false,
                        list_visible:true,
                        editable:false,
                    },
                    {
                        label:"LabelName",
                        model_name:"name",
                        type:"text",
                        value:"",
                        add_modal_visible:true,
                        list_visible:true,
                        editable:true,
                    },
                ]
            },
        }
      }
    },
    created () {
        // this.process()
    },
    methods: {
        async process() {
            this.block_data.loading_block.is_loading = true
            const response = await axios.get('http://localhost:8080/api/sample/list')
            this.block_data.list_block.item_data = response.data.item_data
            this.block_data.list_block.item_data_default = response.data.item_data
            this.block_data.loading_block.is_loading = false
        },
    }

};
</script>

