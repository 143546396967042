<template>
    <div class="image_block" id="customer_scratch">
        <div class="image_box">
            <img id="bg_image" :src="$commonFunction.return_s3_path(scratch_data.bg_image)">
            <div id="temp_logo_box">
                <img :src="$commonFunction.return_s3_path(scratch_data.logo_image)">
            </div>
            <div class="grid-container">
                <div class="grid-item" v-for="index in board[0]">
                    <img :src="return_image_path(index)">
                </div>
                <div class="grid-item" v-for="index in board[1]">
                    <img :src="return_image_path(index)">
                </div>
                <div class="grid-item" v-for="index in board[2]">
                    <img :src="return_image_path(index)">
                </div>
            </div>
            <canvas id="scratchCanvas" ref="scratchCanvas"></canvas>
            <div class="button_box" :style="{ borderColor: scratch_data.color }">
                <button @click="get_prize" :style="{ backgroundColor: scratch_data.color }">
                    獲得賞品を確認する
                </button>
                <p class="error_message">{{error_message}}</p>
            </div>
            <div class="prize_box">
                <img class="prize_bg_img" :src="$commonFunction.return_s3_path(scratch_data.prize_box_image)"> 
                <h1>賞品一覧</h1>
                <p style="margin-bottom: 200px;">下記図柄が縦横斜めいずれかに、3つ揃えば当たりです！</p>
                <img src="/img/scratch_sample.png" class="scratch_sample">
                <div class="prize_row" v-if="scratch_data['1p_start_count'] > 0">
                    <img :src="$commonFunction.return_s3_path(scratch_data.prize1_image)">
                    <p>{{scratch_data.first_prize_label}}</p>
                </div>
                <div class="prize_row" v-if="scratch_data['2p_start_count'] > 0">
                    <img :src="$commonFunction.return_s3_path(scratch_data.prize2_image)">
                    <p>{{scratch_data.second_prize_label}}</p>
                </div>
                <div class="prize_row" v-if="scratch_data['3p_start_count'] > 0">
                    <img :src="$commonFunction.return_s3_path(scratch_data.prize3_image)">
                    <p>{{scratch_data.third_prize_label}}</p>
                </div>
                <div class="prize_row" v-if="scratch_data['4p_start_count'] > 0">
                    <img :src="$commonFunction.return_s3_path(scratch_data.prize4_image)">
                    <p>{{scratch_data.fourth_prize_label}}</p>
                </div>
                <div class="prize_row" v-if="scratch_data['5p_start_count'] > 0">
                    <img src="/img/miss_prize.png">
                    <p>{{scratch_data.fifth_prize_label}}</p>
                </div>
            </div>
        </div>
    </div>
    <CustomerFooterBlock :template_id="scratch_data.template_id" class="print_ignore"></CustomerFooterBlock>
</template>
  
<script>
import CustomerFooterBlock from "@/components/blocks/custom/CustomerFooterBlock";
export default {
    components: {
        CustomerFooterBlock,
    },
    data() {
        return {
            imagePaths:[],
            board:[],
            isScratching: false,
            is_active_page: true,
            ctx: null,
            scratch_data:[]
        };
    },
    mounted() {
        this.process()
    },
    methods: {
        async process(){
            this.init_scratch();
            this.initializeCanvas();
            this.addEventListeners();
        },
        initializeCanvas() {
            if(this.is_active_page){
                const canvas = this.$refs.scratchCanvas;
                const ctx = canvas.getContext('2d');
                canvas.width = 300;
                canvas.height = 310;
    
                // ctx.fillStyle = '#cccccc';
                // ctx.fillRect(0, 0, canvas.width, canvas.height);
                const image = new Image();
                image.onload = () => {
                    ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
                };
                image.src = '/img/scratch_canvas.png';

        
                this.ctx = ctx;
            }
        },
        startScratch(e) {
            this.isScratching = true;
            this.ctx.beginPath();
            if(e.type === 'touchstart') {
            e.preventDefault();
            }
        },
        endScratch() {
            this.isScratching = false;
        },
        doScratch(e) {
            if (!this.isScratching) return;
            let rect = this.$refs.scratchCanvas.getBoundingClientRect();
            let x, y;
            if (e.type === 'touchmove') {
                x = e.touches[0].clientX - rect.left;
                y = e.touches[0].clientY - rect.top;
                e.preventDefault();
            } else {
                x = e.clientX - rect.left;
                y = e.clientY - rect.top;
            }
            this.ctx.globalCompositeOperation = 'destination-out';
            this.ctx.beginPath();
            this.ctx.arc(x, y, 20, 0, Math.PI * 2);
            this.ctx.fill();
        },
        addEventListeners() {
            if(this.is_active_page){
                const canvas = this.$refs.scratchCanvas;
                canvas.addEventListener('touchstart', this.startScratch);
                canvas.addEventListener('touchend', this.endScratch);
                canvas.addEventListener('touchmove', this.doScratch);
            }
        },
        // スクラッチの結果を取得し、配置を確定
        async init_scratch() {
            let response = await this.$commonFunction.axios_get(`user/scratch/result/detail/${this.$route.query.scratch_code}`)
            if(response.status == 200 && response.data.item_data){
                const matrix = [];
                for (let i = 0; i < 9; i += 3) {
                    const row = response.data.item_data.pattern.substring(i, i + 3).split('').map(Number);
                    matrix.push(row);
                }
                this.board = matrix
            } 
            // くじの情報を取得する
            response = await this.$commonFunction.axios_get(`customer/scratch/detail/${this.$route.query.sid}`)
            if(response.status === 200){
                this.scratch_data = response.data.item_data[0]
                this.imagePaths.push(this.$commonFunction.return_s3_path(this.scratch_data.prize1_image))
                this.imagePaths.push(this.$commonFunction.return_s3_path(this.scratch_data.prize2_image))
                this.imagePaths.push(this.$commonFunction.return_s3_path(this.scratch_data.prize3_image))
                this.imagePaths.push(this.$commonFunction.return_s3_path(this.scratch_data.prize4_image))
                this.imagePaths.push(this.$commonFunction.return_s3_path(this.scratch_data.prize5_image))
            } else if (response.status == 500){
                this.$router.push({name:"ServerErrorPage"});
            }
        },
        return_image_path(index){
            return this.imagePaths[index]
        },
        // 賞品一覧のリンク
        get_prize(){
            let query_parm = {
                sid:this.$route.query.sid,
            }
            window.scrollTo(0, 0);
            this.$router.push({name:'CustomerPrizePage', query:query_parm});

        }
    }
}
</script>

<style>
    /* スクラッチページ用の配置 */
    #customer_scratch #bg_image {height: 1900px;}
    #customer_scratch .prize_box{top: 700px;}
    #customer_scratch .image_box .button_box {top: 1190px;}
    
    /* スクラッチボックス */
    #customer_scratch #scratchCanvas {width: 300px;height: 310px;display: block;position: absolute;top: 300px;left: 50%;transform: translate(-50%, -50%);}
    #customer_scratch .grid-container {display: grid;grid-template-columns: repeat(3, 1fr);width: 300px;height: 310px;position: absolute;top: 300px;left: 50%;transform: translate(-50%, -50%);border: solid red;}
    #customer_scratch .grid-item{width: 100%;overflow: hidden;position: relative; border: solid red; }
    #customer_scratch .grid-item img {width: 80%;margin: 10px;height: 80px;top: 0;left: 0%;transform: none;}
</style>