<template>
  <div class="image_block" id="tos_page">
      <div class="image_box" id="policy_height">
        <div class="container">
          <h2>- プライバシーポリシー -</h2>
          <p>
            当サイト(https://scratch-app.jp/)は、ササエルデザイン株式会社(以下、当社)が管理し、当社の事業内容及びサービス(以下、本サービス)を紹介するサイトであり、本サービスをご利用するお客様(以下、利用者）と当社に適用される条件を定めています。<br>
            当サイトを利用される前に次の事項を確認頂き、了解頂いた上でご利用ください。<br><br>
            個人情報保護方針<br>
            当社は、以下のとおり個人情報保護方針を定め、個人情報保護の仕組みを構築し、個人情報の保護を推進致します。
          </p>
          <h3>個人情報の利用目的</h3>
          <p>
            本ウェブサイトでは、お客様からのお問い合わせ時に、お名前、e-mailアドレス等の個人情報をご入力いただく場合がございますが、これらの個人情報はご提供いただく際の目的以外では利用いたしません。お客様からお預かりした個人情報は、当社からのご連絡や業務のご案内やご質問に対する回答として、電子メールや資料のご送付に利用いたします。
          </p>
          <h3>個人情報の第三者への開示・提供の禁止</h3>
          <p>
            当社は、お客様よりお預かりした個人情報を適切に管理し、個人情報を第三者に開示いたしません。
          </p>
          <h3>法令、規範の遵守と見直し</h3>
          <p>
            当社は、保有する個人情報に関して適用される日本の法令、その他規範を遵守するとともに、本ポリシーの内容を適宜見直し、その改善に努めます。改定された条項は、当サイトに掲載された時点から適用されます。利用者の方へその都度ご連絡はいたしかねますので、ご利用の際には本ページの最新の内容をご参照ください。
          </p>
          <h3>Cookieの使用</h3>
          <p>
            Cookieとは、ウェブサーバーからお客様のブラウザへ送信・保持される情報です。 当社ウェブサイトのアクセス性・利便性を向上させる目的で、Google, Inc.のGoogle Analyticsを利用し、それにより生成されるCookieを通じて本アプリの閲覧状況などの情報を収集する場合があります。このデータはお客様個人を特定・追跡するものではありません。 多くのブラウザは、はじめからCookieが利用されるように設定されていますが、お客様はCookieの受け入れに際して警告を表示したり、拒否するようにブラウザの設定を変更することが可能です。 本サービスに第三者企業のサービスとの連携やタグの設置などを行うことにより、第三者企業がCookie情報を取得し利用する場合があります。その場合に取得されるCookie情報は、当社のサーバーではなく、第三者企業のサーバーに蓄積され、各第三者企業のプライバシーポリシーに従って管理されます。 お客様は本サイトを利用するにあたり、上記内容でのCookieの利用に同意したものとみなします。※Google,Inc.が収集する情報・プライバシーポリシーについては、Google社プライバシーポリシーをご確認ください。
          </p>
          <h3>お問い合わせ窓口</h3>
          <p>
            当社の個人情報の取扱に関するお問い合せは、当HP内の問い合わせフォームよりご連絡ください。
          </p>
          <h3>免責事項</h3>
          <p>
            当社は以下の事項につき、一切の責任を負わないものとします。<br>
            当サイトへの情報・資料の掲載には注意を払っておりますが、掲載された情報の正確性・安全性・有用性について一切を負いません。<br>
            当サイトの御利用につき生じた、何らかのトラブルや損失・損害等につきましては、理由の如何に関わらず当社は一切責任を負いません。<br>
            当サイトが紹介しているウェブサイトやソフトウェアの合法性、正確性、道徳性、最新性、適切性、著作権の許諾や有無など、その内容について一切責任を負いません。<br>
            当サイトからリンクやバナーなどによって他のサイトに移動された場合、移動先サイトで提供される情報、サービス等について一切責任を負いません。<br>
            本サービスの中断・停止又は内容変更によってお客様が受ける損害については一切責任を負いません。<br>
          </p>



          <br>
          <div class="button_box">
            <button @click="link_to" style="background-color: rgb(98, 239, 222);">
                TOPに戻る
            </button>
            <p class="error_message">{{error_message}}</p>
          </div>
        </div>
      </div>
  </div>
  <FooterBlock></FooterBlock>
</template>
  
<script>
  export default {
    components: {
    },
      data() {
        return {
          scratch_data:[]
        }
      },
      created () {
      },
      methods: {
        link_to(){
          if(this.$route.query.sid){
            let query_parm = {
              sid:this.$route.query.sid,
            }
            this.$router.push({name:'CustomerTopPage', query:query_parm});
            } else {
            this.$router.push({name:'SampleTopPage'});
          }
        }
      }
  };
  </script>

<style>
  #tos_page .image_box .container{background-color: white;width: 95%;height:100%;margin: 0 auto;display: block;position: absolute;top: 0;}
  #tos_page h2{text-align: center;font-size: 1.2rem;font-weight: bold;margin: 50px auto 20px auto;}
  #tos_page h3{font-weight: bold;border-bottom: solid 0.1px;padding-bottom: 10px;padding-top: 10px;}
  #tos_page p {font-size: 0.8rem;padding-top: 10px;line-height: 18px;padding-bottom: 10px;}
  #tos_page .image_box {background-color: rgb(98, 239, 222);height: 1600px;}

  #tos_page .image_box .button_box {
    position: relative;
    position: relative;
    margin: 15px auto;
    top:0;
    left: 0;
    transform: none;
  }
  /* #tos_page #policy_height{
    height: 1050px;
  }
  @media (max-width: 600px) {
    #tos_page .image_box {
      height: 2750px;
    }
    #tos_page #policy_height{
      height: 1750px;
    }
  } */

  @media (min-width: 1201px) {
    #tos_page #policy_height{
      height: 1050px;
    }
  }
  @media (max-width: 1200px) {
    #tos_page #policy_height{
      height: 1050px;
    }
  }
  @media (max-width: 992px) {
    #tos_page #policy_height{
      height: 1200px;
    }
  }
  @media (max-width: 768px) {
    #tos_page #policy_height{
      height: 1350px;
    }
  }
  @media (max-width: 530px) {
    #tos_page #policy_height{
      height: 1550px;
    }
  }
  @media (max-width: 460px) {
    #tos_page #policy_height{
      height: 1600px;
    }
  }
  @media (max-width: 420px) {
    #tos_page #policy_height{
      height: 1800px;
    }
  }

</style>
  
  