<template>
    <div id="calc">
        <div class="item_box" v-for="(group, index) in radioGroups" :key="index">
            <h2>{{group.name}}</h2>
          <div class="radio_box" v-for="option in group.options" :key="option.value">
            <input type="radio" :name="group.name" :value="option.value" v-model="group.selected">
            {{ option.label }} (¥{{ option.price }})
          </div>
        </div>
      
        <h2>ケーキ（サイズ）</h2>
        <div class="cp_ipselect cp_sl01">
            <select class="item_box_2" v-model="selectedList">
              <option v-for="option in listOptions" :value="option.value" :key="option.value">
                {{ option.label }} (¥{{ option.price }})
              </option>
            </select>
        </div>
      
        <h2>キャンディー</h2>
        <div class="item_box" v-for="(option, index) in checkboxOptions" :key="index">
          <input type="checkbox" :value="option.value" v-model="selectedCheckboxes">
          {{ option.label }} (¥{{ option.price }})
        </div>
      
        <div>
            <p class="total_price">
                合計: <span class="price_view">¥{{ currentTotal.toLocaleString() }}</span>
            </p>
        </div>
            
      </div>
  </template>
  
  <script>
  export default {
    data() {
        return {
            currentTotal: 0,

            radioGroups: [
                { name: 'チョコレート', selected: null, options: 
                    [
                        { label: 'ビター', value: 'A', price: 100 }, 
                        { label: 'ミルク', value: 'B', price: 200 },
                        { label: 'いちご', value: 'C', price: 300 }
                    ] 
                },
                { name: 'クッキー', selected: null, options: 
                    [
                        { label: 'ピスタチオ', value: 'C', price: 300 }, 
                        { label: 'アーモンド', value: 'D', price: 400 },
                        { label: 'チョコチップ', value: 'D', price: 400 }
                    ] 
                },
            ],
            selectedList: '',
            listOptions: [
                { label: '10号', value: '1', price: 1000 },
                { label: '20号', value: '2', price: 1500 },
                { label: '30号', value: '3', price: 2000 }
            ],
            selectedCheckboxes: [],
            checkboxOptions: [
                { label: 'ミルキー', value: '1', price: 120 },
                { label: 'さくまドロップ', value: '2', price: 100 },
                { label: 'べっこう飴', value: '3', price: 140 },
                { label: 'キシリクリスタル', value: '4', price: 200 },
                { label: 'のど飴', value: '5', price: 240 }
            ]

        }
    },
    computed: {
        totalPrice() {
            let total = 0;
            
            // ラジオボタンの合計
            this.radioGroups.forEach(group => {
                const selectedOption = group.options.find(option => option.value === group.selected);
                if (selectedOption) {
                total += selectedOption.price;
                }
            });

            // セレクトリストの合計
            const selectedListOption = this.listOptions.find(option => option.value === this.selectedList);
            if (selectedListOption) {
                total += selectedListOption.price;
            }

            // チェックボックスの合計
            this.selectedCheckboxes.forEach(value => {
                const option = this.checkboxOptions.find(option => option.value === value);
                if (option) {
                total += option.price;
                }
            });

            return total;
        }
    },
    watch: {
        totalPrice(newVal) {
        this.animateTotal(this.currentTotal, newVal);
        }
    },
    methods: {
    animateTotal(startValue, endValue) {
      const stepTime = 20;
      const steps = 20;
      let currentStep = 0;

      const increment = (endValue - startValue) / steps;

      const interval = setInterval(() => {
        currentStep++;
        this.currentTotal += increment;

        if (currentStep >= steps) {
          clearInterval(interval);
          this.currentTotal = endValue;
        }
      }, stepTime);
    }
  }

  };
  </script>

<style>
    #calc{
        width: 70%;
        margin: 20px auto;
        padding: 20px;
        border-radius: 20px;
        background: white;
    }
    #calc .item_box{
        margin: 20px auto;
    }
    #calc .radio_box{
        margin: 10px;
    }
    #calc h2{
        color: skyblue;
        font-size: 1.5rem;
        font-weight: bold;
    }
    .total_price{
        font-size: 2rem;
        color: red;
    }
    .price_view{
        font-size: 2.5rem;
        font-weight: bold;
        color: red;
    }
    .cp_ipselect {
  overflow: hidden;
  width: 50%;
  margin: 2em 0;
  text-align: center;
}
.cp_ipselect select {
  width: 100%;
  padding-right: 1em;
  cursor: pointer;
  text-indent: 0.01px;
  text-overflow: ellipsis;
  border: none;
  outline: none;
  background: transparent;
  background-image: none;
  box-shadow: none;
  -webkit-appearance: none;
  appearance: none;
}
.cp_ipselect select::-ms-expand {
    display: none;
}
.cp_ipselect.cp_sl01 {
  position: relative;
  border-radius: 2px;
  border: 2px solid skyblue;
  border-radius: 50px;
  background: #ffffff;
}
.cp_ipselect.cp_sl01::before {
  position: absolute;
  top: 0.8em;
  right: 0.8em;
  width: 0;
  height: 0;
  padding: 0;
  content: '';
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid skyblue;
  pointer-events: none;
}
.cp_ipselect.cp_sl01 select {
  padding: 8px 38px 8px 8px;
  color: black;
}
    
</style>
  
  