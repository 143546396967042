<template>
    <LoadingBlock v-model="block_data.loading_block" v-bind:loading_block="block_data.loading_block"></LoadingBlock>
    <div v-show="!block_data.loading_block.is_loading">
      <SampleHeaderBlock></SampleHeaderBlock>
      <div class="container">
        <PageGuideBlock :block_data="block_data"></PageGuideBlock>
        <TabIndexBlock :block_data="block_data"></TabIndexBlock>
        <TabContentBlock v-show="block_data.tab_index_block.index == 1" :list_items="block_data.tab_content_block.list_plan"></TabContentBlock>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios'
  export default {
      data() {
        return {
          block_data:{
              query_param:{},
              loading_block:{
                  is_loading:false
              },
              return_block:{
                  return_page_name:"AdminTopPage",
                  return_page_is_query:true,
                  page_guides:[
                      {
                          name:"PageNameTop",
                          link:"AdminTopPage",
                          is_query:false,
                      },
                      {
                          name:"PageNameAdminPlanList",
                          link:"",
                          is_query:false,
                      },
                  ],
              },
              tab_index_block:{
                  index:1,
                  labels:[
                      {index:1,label:"PageNameAdminPlanList"},
                  ],
              },
              tab_content_block:{
                    list_plan:{
                      modal_condition:0,
                      list_search_property:{
                          label:"PageNameAdminPlanList",
                          source_list:[],
                          source_list_default:[],
                          is_search:false,
                          search_word: "",
                      },
                      add_menu_button_block: [
                          {
                              label: "CommonRegister",
                              method_type: "add",
                              link: "",
                          },
                      ],
                      current_page:1,
                      per_page:5,
                      post_add_url:"admin/user/plan/add",
                      post_edit_url:"admin/user/plan/edit",
                      post_delete_url:"admin/user/plan/delete",
                      select_data:{},
                      item_name:"PageNameAdminPlanList",
                      list_buttons:[
                          {
                              type:"edit",
                              label:"CommonEdit"
                          },
                          {
                              type:"delete",
                              label:"CommonDelete"
                          }
                      ],
                      item_data:[],
                      item_data_default:[],
                      item_models:[
                          {
                            label:"LabelID",
                            model_name:"id",
                            type:"text",
                            value:"",
                            add_modal_visible:false,
                            list_visible:false,
                            editable:false,
                          },
                          {
                            label:"LabelNamePlan",
                            model_name:"name",
                            type:"text",
                            value:"",
                            add_modal_visible:true,
                            list_visible:true,
                            editable:true,
                          },
                          {
                            label:"LabelPlanPrice",
                            model_name:"price",
                            type:"price",
                            value:"",
                            add_modal_visible:true,
                            list_visible:true,
                            editable:true,
                          },
                          {
                            label:"LabelDescription",
                            model_name:"description",
                            type:"text",
                            value:"",
                            add_modal_visible:true,
                            list_visible:true,
                            editable:true,
                          },
                      ]
                  }  
              }
          }
        }
      },
      created () {
          this.process()
      },
      methods: {
          async process() {
              this.block_data.loading_block.is_loading = true
              // ユーザー一覧取得
              let response = await this.$commonFunction.axios_get('admin/user/plan/list')
              this.block_data.tab_content_block.list_plan.item_data = response.data.item_data
              this.block_data.tab_content_block.list_plan.item_data_default = response.data.item_data
              this.block_data.loading_block.is_loading = false
          },
      }
  
  };
  </script>
  
  