<template>
  <div class="image_block" id="server_error">
      <div class="image_box">
        <img id="bg_image" src="/img/scratch_bg1.png">
          <div id="temp_logo_box">
              <img src="/img/temp_logo1.png">
          </div>
          <div class="container" id="policy" style="margin-top: 120px;">
            <div v-if="is_check_payment">
              <p>決済結果を確認中です。</p>
              <p>このまましばらくお待ちください</p>
            </div>
            <div v-if="!is_check_payment">
              <p>決済結果を確認できませんでした。</p>
              <p>ページを更新しても結果が表示されない場合、下記連絡先までご連絡ください</p>
              <p>info@sasaeru.co.jp</p>
            </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
          is_check_payment:true
        };
    },
    mounted() {
        this.process()
    },
    methods: {
        async process(){
          // 決済状況を確認する
          let storedUUID = localStorage.getItem('uniqueUUID');
            if (!storedUUID) {
                storedUUID = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                const r = Math.random() * 16 | 0,
                        v = c == 'x' ? r : (r & 0x3 | 0x8);
                    return v.toString(16);
                });
                localStorage.setItem('uniqueUUID', storedUUID);
            }
            let post_data = {
              merchant_payment_id:this.$route.query.key,
              uuid:storedUUID,
            }
            let response = await this.$commonFunction.axios_post(`user/scratch/customer/payment/result`,post_data)
            if(response.status === 200){
              let query_parm = {
                  sid:response.data.scratch_id,
                  scratch_code:response.data.scratch_code
              }
              this.$router.push({name:'CustomerScratchPage', query:query_parm});
            } else if (response.status == 500){
                this.$router.push({name:"ServerErrorPage"});
            } else {
              this.is_check_payment = false
            }
        },
    }
}
</script>

