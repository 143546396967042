

<!-- 編集のモーダル -->
<template>
  <div class="modal-content fadeIn" v-if="list_items.modal_condition == 2">
    <div class="modal-headline">
      <button @click="modal_control(0)">×</button>
    </div>
    <div class="modal-main">
      <h2>{{ $t(list_items.item_name) }}{{ $t("CommonEdit") }}</h2>
        <div 
            v-for="(label_data, index) in list_items.item_models"
            :key="'modal_edit_' + index"
            v-show="visible_condition(label_data)"
        >
          <!-- 編集可能入力 -->
          <div class="content" v-if="label_data.editable">
            <p>{{ $t(label_data.label) }}:</p>&nbsp;
            <!-- テキストの入力 -->
            <input v-if="label_data.type!='list'" type="text" v-model="list_items.select_data[label_data.model_name]"/>
            <!-- リストの入力 -->
            <select v-if="label_data.type=='list'" v-model="list_items.select_data[label_data.model_name]">
              <option v-for="option in label_data.option" v-bind:value="option.value" v-bind:key="option.id">
                {{ option.symbol }}
              </option>
            </select>
          </div>
          <!-- 編集不可の入力 -->
          <div class="content" v-if="!label_data.editable">
            <p>{{ $t(label_data.label) }}:</p>
            <span>
              <input type="text" disabled :value="list_items.select_data[label_data.model_name]"/><br />
            </span>
          </div>
        </div>
        <div class="button_box">
          <button class="btn_blue" @click="post_edit_data()">{{ $t("CommonSave")}}</button>
        </div>
        <div class="content">
          <p class="error_message_color">{{ $t(error_message)}}</p>
        </div>
    </div>
  </div>
  <!-- 削削のモーダル -->
  <div class="modal-content fadeIn" v-if="list_items.modal_condition == 3">
    <div class="modal-headline">
      <button @click="modal_control(0)">×</button>
    </div>
    <div class="modal-main">
      <h2>{{ $t(list_items.item_name) }}{{ $t("CommonDelete") }}</h2>
      <div class="content" style="justify-content: center;">
        <p class="error_message_color">{{list_items.select_data.title}}</p>
      </div>
      <div class="content" style="justify-content: center;">
        <p class="error_message_color">を削除しますか？</p>
      </div>
      <div class="button_box">
        <button class="btn_red" @click="post_delete_data()">{{ $t("CommonDelete")}}</button>
      </div>
      <div class="content">
        <p class="error_message_color">{{ $t(error_message)}}</p>
      </div>
    </div>
  </div>
  <!-- 画像プレビューのモーダル -->
  <div class="modal-content fadeIn modal_image_preview" v-if="list_items.modal_condition == 4">
    <div class="modal-headline">
      <button @click="modal_control(0)">×</button>
    </div>
    <div class="modal-main">
      <p>
        <img v-bind:src="s3_path + prop_data.list_block.select_data.path" loading="lazy">
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["list_items"],
  data() {
    return {
      error_message:""
    };
  },
  // created() {
  // },
  methods: {
    // 表示の可否を判断
    visible_condition(label_data){
      // リストの値ならば非表示
      let condition = 'is_list_value' in label_data ? false : (label_data.list_visible || label_data.type == "list")
      return condition
    },
    // 親のmodal_conditionを操作して表示を切り替える
    modal_control(condition) {
      this.list_items.modal_condition = condition
    },
    // データを新規登録する
    async api_post(){
      let post_data = {}
      for (const item_model of this.list_items.item_models){
        post_data[item_model.model_name] = item_model.value 
      }
      const response = await this.$axios.post(`${process.env.VUE_APP_HOST_DOMAIN}${this.list_items.post_add_url}`, post_data)
      if (response.status === 200) {
        this.$router.go(0);
      } else {
        console.log("error")
      }
    },
    // データ編集用メソッド
    async post_edit_data(){
      let response = await this.$axios.post(`${process.env.VUE_APP_HOST_DOMAIN}${this.list_items.post_edit_url}` ,this.list_items.select_data);
      // 成否判定
      if(response.status === 200){
        this.$router.go(0);
      } else{
        console.log("error")
      }
    },
    // データ削除用メソッド
    async post_delete_data(){
      let response = await this.$commonFunction.axios_post(`${this.list_items.post_delete_url}` ,this.list_items.select_data)
      // 成否判定
      if(response.status === 200){
        this.$router.go(0);
      } else{
        console.log("error")
      }
    }
  },
};
</script>

<style>
/* モーダル */
#overlay {z-index: 3;position: fixed;top: 0;left: 0;width: 100%;height: 100%;background-color: rgba(0, 0, 0, 0.5);display: flex;align-items: center;justify-content: center;}    
.modal-content {position: relative;border-radius: 10px;z-index: 2;width: 70%;padding: 0;background-color: #F2F1F2!important;overflow: hidden;box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);max-height: 95vh;overflow-y: scroll;}.modal-content .button_box {margin: 20px auto;}
.modal-content button {height: 60px;font-weight: bold;text-align: center;padding: 5px 15px;border: none;font-size: 1.2rem;}
.modal-content .modal-headline button {font-size: 2rem;background-color: #F2F1F2;color: #000;width: auto;}
.modal-content .modal-main button {padding: 5px 15px;width: 100%;border-radius: 8px;}
.fadeIn {animation-name: fadeInAnime;animation-duration: 0.3s;animation-fill-mode: forwards;opacity: 0;}
.modal-headline {display: flex;justify-content: end;padding: 0 1em;align-items: center;}
.modal-headline h1 {color: #fff;}
.modal-main {}
.modal-main h2{text-align: center;margin-top: 0;color: #2c469f;}
.modal-main .content {margin: 20px auto;display: flex;width: 90%;justify-content: end;}
.modal-main .delete-info {display: block;text-align: center;margin: 0 auto;justify-content: center;}
.modal-main .content select{height: 60%;width: 83%;margin-top: 10px;}
.modal-main .content input{height: 60%;width: 80%;margin-top: 10px;}
.modal-main .content span{width:  80%;}
.modal_image_preview{background-color: transparent;box-shadow: none;}
.modal_image_preview .modal-headline button {background-color: transparent;font-size: 4rem;}
.modal_image_preview .modal-main p {width: 60%;margin: 0 auto;}
.modal_image_preview .modal-main p img{width: 100%;}
.modal-main .content span{text-align: center;}
.modal-main .content_label{justify-content: start;}
#overlay .modal-content .button_box {margin: 20px auto;}
#overlay .button_box {  width: 20%;  text-align: center;}
#overlay .modal-content button {height: 60px;font-weight: bold;text-align: center;padding: 5px 15px;border: none;font-size: 1.2rem;}
#overlay .btn_blue {  color: white;  background: rgb(30, 187, 226);}
#overlay .btn_red{color: white;background: red;}
#overlay .btn_silver{color: white;background: #9A9A9A;}
@keyframes fadeInAnime {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* レスポンシブ設定 */
@media(max-width:800px){
  .modal-content{width: 90%;}
  .modal-main .content input {font-size: 17px;}
}
</style>
