<template>
  <header class="site-header main_color">
      <div class="wrapper site-header__wrapper">
        <div class="site-header__start">
          <a class="brand">
            <img src="/img/head_logo.png">
          </a>
        </div>
        <div class="site-header__middle pc">
          <nav class="nav">
            <button class="nav__toggle" 
              @click="toggleNav" 
              :aria-expanded="ariaExpanded.toString()" 
              :aria-label="ariaLabel">
              <img src="/img/menu-icon.png">
            </button>
            <ul class="nav__wrapper" :class="{ active: isActive }">
              <li class="nav__item" v-for="data in header_block.link_items" :key="data.id">
                <router-link :key="data.id" :to="{ name: data.link }">
                  {{ $t(data.label) }}
                </router-link>
              </li>
            </ul>
          </nav>
        </div>
        <!-- <div class="site-header__end pc">
          <a v-if="is_login_now" class="button" @click="logout">{{ $t("CommonLogout") }}</a>
          <a v-if="!is_login_now" class="button" href="#">{{ $t("CommonLogin") }}</a>
        </div> -->
      </div>
  </header>  
</template>

<script>
import Cookies from 'js-cookie';
export default {
  props: ["header_block"],
    data() {
        return {
            isActive: false,
            ariaExpanded: false,
            ariaLabel: 'menu',
            user_name:"ゲスト",
            is_login_now:false,
            button_active:true,
        };
    },
    created(){
      this.process()
    },
    methods: {
        toggleNav() {
          this.isActive = !this.isActive;
          this.ariaExpanded = this.isActive;
          this.ariaLabel = this.isActive ? 'close menu' : 'menu';
        },
        process(){
          let user_name = Cookies.get('user_name') ? Cookies.get('user_name') : ""
          if(Cookies.get('user_name')){
            this.user_name = Cookies.get('user_name')
            this.is_login_now = true
          } else {
            this.user_name = "ゲスト"
            this.is_login_now = false
          }
        },
        async logout () {
          if(this.button_active){
            const response = await this.$commonFunction.axios_post('authorization/logout', "")
            if (response.status === 200) {
              Cookies.remove('user_id')
              Cookies.remove('user_type')
              Cookies.remove('user_name')
              this.$router.go(0);
            } else {
              this.button_active = true
            }
          } 
        }
    }
};
</script>

<style>
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
  .site-header__wrapper.bottom {display: flex;justify-content: flex-end;align-items: center;height: 40px;}
  html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {margin: 0;padding: 0;border: 0;font-size: 100%;font: inherit;vertical-align: baseline}
  article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {display: block}
  body {line-height: 1}
  ol, ul {list-style: none}
  blockquote, q {quotes: none}
  blockquote:before, blockquote:after, q:before, q:after {content: '';content: none}
  table {border-collapse: collapse;border-spacing: 0}
  .wrapper {width: 90%;padding-left: 0.3rem;padding-right: 0.3rem;margin-left: auto;margin-right: auto;}
  *, *:before, *:after {box-sizing: border-box;}
  a {text-decoration: none;color: #222;}
  html {-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;}
  body {font-family: "Roboto", sans-serif;}
  .sr-only {position: absolute;clip: rect(1px, 1px, 1px, 1px);padding: 0;border: 0;height: 1px;width: 1px;overflow: hidden;}
  .button {-webkit-appearance: none;-moz-appearance: none;appearance: none;color: #fff;border: 1px solid;background-color: transparent;min-width: 120px;padding: 0.5rem 1rem;border-radius: 5px;text-align: center;}
  .button svg {display: inline-block;vertical-align: middle;width: 24px;height: 24px;fill: #fff;}
  .button span {display: none;}
  .button--icon {min-width: initial;padding: 0.5rem;}
  .brand {font-weight: bold;font-size: 2rem;color: white;}
  #user_name {font-size: 20px;color: white;}
  .site-header {position: relative;}
  .site-header__wrapper {display: flex;align-items: center;justify-content: space-between;padding-top: 1rem;padding-bottom: 1rem;height: 100px;}
  .nav__item a {display: block;padding: 1rem;}
  .nav__toggle {display: none;}
  @media (min-width: 600px) {
    .button span {display: initial;}
  }
  @media (min-width: 630px) {
    .site-header__wrapper {justify-content: initial;}
    .site-header__middle {margin-left: auto;}
    .nav__wrapper {display: flex;}
  }
  @media (max-width: 629px) {
    .site-header__end {padding-right: 4rem;}
    .nav__wrapper {position: absolute;top: 100%;right: 0;left: 0;z-index: -1;background-color: #d9f0f7;visibility: hidden;opacity: 0;transform: translateY(-100%);transition: transform 0.3s ease-out, opacity 0.3s ease-out;}
    .nav__wrapper.active {visibility: visible;opacity: 1;transform: translateY(0);z-index: 8;}
    .nav__wrapper.active li{border-bottom: dotted 0.1px black;}
    .nav__toggle {display: block;position: absolute;right: 1rem;top: 2.4rem;width: 45px;height: 45px;background: transparent;border: none;}
    .nav__toggle img{width: 45px;}


  }
</style>