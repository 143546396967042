<template>
    <LoadingBlock v-model="block_data.loading_block" v-bind:loading_block="block_data.loading_block"></LoadingBlock>
    <SampleHeaderBlock></SampleHeaderBlock>
    <div class="top_section pc">
        <img src="/img/pc_top_section1.png">
        <img src="/img/pc_top_section2.png">
        <img src="/img/pc_top_section3.png">
        <img src="/img/pc_top_section4.png">
        <img src="/img/pc_top_section5.png">
    </div>
    <div class="top_section sp">
        <img src="/img/sp_top_section1.png">
        <img src="/img/sp_top_section2.png">
        <img src="/img/sp_top_section3.png">
        <img src="/img/sp_top_section4.png">
        <img src="/img/sp_top_section5.png">
    </div>
    <FooterBlock></FooterBlock>
</template>

<script>
    export default {
        data() {
            return {
                block_data:{
                    query_param:{
                    },
                    loading_block:{
                        is_loading:false
                    },
                    return_block:{
                        return_page_name:"",
                        return_page_is_query:true,
                        page_guides:[
                            {
                                name:"PageNameTop",
                                link:"",
                                is_query:false,
                            }
                        ],
                    },
                    link_menu_block:[
                        {
                            title_label:"PageNameSampleCustomer",
                            description_label:"CommonDummyData",
                            link_page_name:"SampleCustomerListPage",
                            image:"/img/icon-relation.png",
                        },
                        {
                            title_label:"PageNameSampleList",
                            description_label:"CommonDummyData",
                            link_page_name:"SampleListPage",
                            image:"/img/icon-file.png",
                        },
                        {
                            title_label:"PageNameSampleSetting",
                            description_label:"CommonDummyData",
                            link_page_name:"SampleSettingPage",
                            image:"/img/icon-setting.png",
                        },
                    ]
                }
            }
        }
    };
</script>


<style>
    .top_section img{
        width: 100%;
    }
</style>