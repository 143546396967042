import { createRouter, createWebHistory } from 'vue-router'
import ServerErrorPage from '@/components/pages/ServerErrorPage.vue'
import ErrorPage from '@/components/pages/ErrorPage.vue'
import PcPage from '@/components/pages/PcPage.vue'
import CustomerDisablePage from '@/components/pages/customer/CustomerDisablePage.vue'
import SampleLoginPage from '@/components/pages/sample/SampleLoginPage.vue'
import SampleTopPage from '@/components/pages/sample/SampleTopPage.vue'
import SampleListPage from '@/components/pages/sample/SampleListPage.vue'
import SampleCustomerListPage from '@/components/pages/sample/SampleCustomerListPage.vue'
import SampleSettingPage from '@/components/pages/sample/SampleSettingPage.vue'
import AdminLoginPage from '@/components/pages/admin/AdminLoginPage.vue'
import AdminTopPage from '@/components/pages/admin/AdminTopPage.vue'
import AdminUserListPage from '@/components/pages/admin/AdminUserListPage.vue'
import AdminPlanListPage from '@/components/pages/admin/AdminPlanListPage.vue'
import AdminDesignPage from '@/components/pages/admin/AdminDesignPage.vue'
import UserCalcPage from '@/components/pages/user/UserCalcPage.vue'
import UserLoginPage from '@/components/pages/user/UserLoginPage.vue'
import UserTopPage from '@/components/pages/user/UserTopPage.vue'
import UserScratchAddPage from '@/components/pages/user/UserScratchAddPage.vue'
import UserScratchDetailPage from '@/components/pages/user/UserScratchDetailPage.vue'
import UserScratchViewPage from '@/components/pages/user/UserScratchViewPage.vue'
import UserPaymentLogPage from '@/components/pages/user/UserPaymentLogPage.vue'
import CustomerTopPage from '@/components/pages/customer/CustomerTopPage.vue'
import CustomerScratchPage from '@/components/pages/customer/CustomerScratchPage.vue'
import CustomerScratchTestPage from '@/components/pages/customer/CustomerScratchTestPage.vue'
import CustomerPrizePage from '@/components/pages/customer/CustomerPrizePage.vue'
import CustomerThanksPage from '@/components/pages/customer/CustomerThanksPage.vue'
import CustomerTOSPage from '@/components/pages/customer/CustomerTOSPage.vue'
import CustomerTokushoPage from '@/components/pages/customer/CustomerTokushoPage.vue'
import CustomerPolicyPage from '@/components/pages/customer/CustomerPolicyPage.vue'
import CustomerPaymentCompleatPage from '@/components/pages/customer/CustomerPaymentCompleatPage.vue'

import Cookies from 'js-cookie'

const routes = [
  {
    path: '/:pathMatch(.*)*',
    name: 'ErrorPage',
    component: ErrorPage,
  },
  {
    path: '/error/pcpage',
    name: 'PcPage',
    component: PcPage,
  },
  {
    path: '/error/server',
    name: 'ServerErrorPage',
    component: ServerErrorPage
  },
  {
    path: '/error/disable',
    name: 'CustomerDisablePage',
    component: CustomerDisablePage
  },
  {
    path: '/',
    name: 'SampleTopPage',
    component: SampleTopPage,
  },
  {
    path: '/sample/login',
    name: 'SampleLoginPage',
    component: SampleLoginPage
  },
  {
    path: '/sample/list',
    name: 'SampleListPage',
    component: SampleListPage,
  },
  {
    path: '/sample/customer/list',
    name: 'SampleCustomerListPage',
    component: SampleCustomerListPage,
  },
  {
    path: '/sample/setting/',
    name: 'SampleSettingPage',
    component: SampleSettingPage,
  },
  {
    path: '/e2xt3ap1/login',
    name: 'AdminLoginPage',
    component: AdminLoginPage,
  },
  {
    path: '/e2xt3ap1/home',
    name: 'AdminTopPage',
    component: AdminTopPage,
    meta:{requiresAuthAdmin: true}
  },
  {
    path: '/e2xt3ap1/user',
    name: 'AdminUserListPage',
    component: AdminUserListPage,
    meta:{requiresAuthAdmin: true}
  },
  {
    path: '/e2xt3ap1/plan',
    name: 'AdminPlanListPage',
    component: AdminPlanListPage,
    meta:{requiresAuthAdmin: true}
  },
  {
    path: '/e2xt3ap1/design',
    name: 'AdminDesignPage',
    component: AdminDesignPage,
    meta:{requiresAuthAdmin: true}
  },
  {
    path: '/user/login',
    name: 'UserLoginPage',
    component: UserLoginPage,
  },
  {
    path: '/user/home',
    name: 'UserTopPage',
    component: UserTopPage,
    meta:{requiresAuthUser: true,isPc:false}
  },
  {
    path: '/user/calc',
    name: 'UserCalcPage',
    component: UserCalcPage,
  },
  {
    path: '/user/scratch/add',
    name: 'UserScratchAddPage',
    component: UserScratchAddPage,
    meta:{requiresAuthUser: true,isPc:false}
  },
  {
    path: '/user/scratch/detail',
    name: 'UserScratchDetailPage',
    component: UserScratchDetailPage,
    meta:{requiresAuthUser: true,isPc:false}
  },
  {
    path: '/user/scratch/view',
    name: 'UserScratchViewPage',
    component: UserScratchViewPage,
    meta:{requiresAuthUser: true,isPc:false}
  },
  {
    path: '/user/payment/log',
    name: 'UserPaymentLogPage',
    component: UserPaymentLogPage,
    meta:{requiresAuthUser: true,isPc:false}
  },
  {
    path: '/customer/top',
    name: 'CustomerTopPage',
    component: CustomerTopPage,
  },
  {
    path: '/customer/scratch',
    name: 'CustomerScratchPage',
    component: CustomerScratchPage,
  },
  {
    path: '/customer/scratch/test',
    name: 'CustomerScratchTestPage',
    component: CustomerScratchTestPage,
  },
  {
    path: '/customer/prize',
    name: 'CustomerPrizePage',
    component: CustomerPrizePage,
  },
  {
    path: '/customer/thanks',
    name: 'CustomerThanksPage',
    component: CustomerThanksPage,
  },
  {
    path: '/customer/tos',
    name: 'CustomerTOSPage',
    component: CustomerTOSPage,
  },
  {
    path: '/customer/tokusho',
    name: 'CustomerTokushoPage',
    component: CustomerTokushoPage,
  },
  {
    path: '/customer/policy',
    name: 'CustomerPolicyPage',
    component: CustomerPolicyPage,
  },
  {
    path: '/customer/payment/compleat',
    name: 'CustomerPaymentCompleatPage',
    component: CustomerPaymentCompleatPage,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  // スマホ専用ページ
  if (to.matched.some(record => record.meta.isPc)) {
    var width = window.innerWidth;
    if(width < 800) {
      next('/error/pcpage')
    }
  }
  const isLoggedIn = !!Cookies.get('user_id');
  const user_type = Cookies.get('user_type') ? Cookies.get('user_type') : 10;
  // 管理者ログイン
  if (to.matched.some(record => record.meta.requiresAuthAdmin)) {
    if (isLoggedIn && user_type==0) {
      next();
    } else {
      next('/e2xt3ap1/login');
    }
  }
  // ユーザーログイン
  else if(to.matched.some(record => record.meta.requiresAuthUser)) {
    if (isLoggedIn && user_type==1) {
      next();
    } else {
      next('/user/login');
    }
  }
  // ログイン不要画面
  else {
    next();
  }
});


export default router
