<template>
    <LoadingBlock v-model="block_data.loading_block" v-bind:loading_block="block_data.loading_block"></LoadingBlock>
    <div v-show="!block_data.loading_block.is_loading">
      <SampleHeaderBlock></SampleHeaderBlock>
      <div class="container">
        <PageGuideBlock :block_data="block_data"></PageGuideBlock>
        <TabIndexBlock :block_data="block_data"></TabIndexBlock>
        <TabContentBlock v-show="block_data.tab_index_block.index == 1" :list_items="block_data.tab_content_block.list_customer"></TabContentBlock>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios'
  export default {
      data() {
        return {
          block_data:{
              query_param:{},
              loading_block:{
                  is_loading:false
              },
              return_block:{
                  return_page_name:"SampleTopPage",
                  return_page_is_query:true,
                  page_guides:[
                      {
                          name:"PageNameTop",
                          link:"SampleTopPage",
                          is_query:false,
                      },
                      {
                          name:"PageNameSampleCustomer",
                          link:"",
                          is_query:false,
                      },
                  ],
              },
              tab_index_block:{
                  index:1,
                  labels:[
                      {index:1,label:"PageNameSampleCustomer"},
                  ],
              },
              tab_content_block:{
                  list_customer:{
                      modal_condition:0,
                      list_search_property:{
                          label:"PageNameSampleCustomer",
                          source_list:[],
                          source_list_default:[],
                          is_search:false,
                          search_word: "",
                      },
                      add_menu_button_block: [
                          {
                              label: "CommonRegister",
                              method_type: "add",
                              link: "",
                          },
                      ],
                      current_page:1,
                      per_page:5,
                      post_add_url:"/user/customer/add",
                      post_edit_url:"/user/customer/edit",
                      post_delete_url:"/user/customer/delete",
                      select_data:{},
                      item_name:"PageNameSampleCustomer",
                      list_buttons:[
                          {
                              type:"edit",
                              label:"CommonEdit"
                          },
                          {
                              type:"delete",
                              label:"CommonDelete"
                          }
                      ],
                      item_data:[],
                      item_data_default:[],
                      item_models:[
                          {
                              label:"LabelID",
                              model_name:"id",
                              type:"text",
                              value:"",
                              add_modal_visible:false,
                              list_visible:true,
                              editable:false,
                          },
                          {
                              label:"LabelName",
                              model_name:"name",
                              type:"text",
                              value:"",
                              add_modal_visible:true,
                              list_visible:true,
                              editable:false,
                          },
                      ]
                  }  
              }
          }
        }
      },
      created () {
          this.process()
      },
      methods: {
          async process() {
              this.block_data.loading_block.is_loading = true
              // サンプル野菜データ取得
              let response = await axios.get('http://localhost:8080/api/sample/customer/list')
              this.block_data.tab_content_block.list_customer.item_data = response.data.item_data
              this.block_data.tab_content_block.list_customer.item_data_default = response.data.item_data
              this.block_data.loading_block.is_loading = false
          },
      }
  
  };
  </script>
  
  