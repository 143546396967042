<template>
    <LoadingBlock v-model="block_data.loading_block" v-bind:loading_block="block_data.loading_block"></LoadingBlock>
    <div v-show="!block_data.loading_block.is_loading">
      <SampleHeaderBlock></SampleHeaderBlock>
      <div class="container">
        <PageGuideBlock :block_data="block_data"></PageGuideBlock>
        <section id="scratch_block">
            <div class="input_block">
                <p>タイトル</p>
                <input type="text" v-model="block_data.scratch_block[0].value"/>
            </div>
            <div class="input_block">
                <div class="input_block_child">
                    <p>{{block_data.scratch_block[1].label}}</p><input maxlength="10" type="text" v-model="block_data.scratch_block[1].value" placeholder=""/>
                </div>
                <div class="input_block_child">
                    <p>{{block_data.scratch_block[2].label}}</p>
                    <input type="text" v-model="block_data.scratch_block[2].value" placeholder="3"/>
                </div>
            </div>
            <div class="input_block">
                <div class="input_block_child">
                    <p>{{block_data.scratch_block[3].label}}</p><input maxlength="10" type="text" v-model="block_data.scratch_block[3].value"/>
                </div>
                <div class="input_block_child">
                    <p>{{block_data.scratch_block[4].label}}</p><input type="text" v-model="block_data.scratch_block[4].value" placeholder="7"/>
                </div>
            </div>
            <div class="input_block">
                <div class="input_block_child">
                    <p>{{block_data.scratch_block[5].label}}</p><input maxlength="10" type="text" v-model="block_data.scratch_block[5].value"/>
                </div>
                <div class="input_block_child">
                    <p>{{block_data.scratch_block[6].label}}</p><input type="text" v-model="block_data.scratch_block[6].value" placeholder="15"/>
                </div>
            </div>
            <div class="input_block">
                <div class="input_block_child">
                    <p>{{block_data.scratch_block[7].label}}</p><input maxlength="10" type="text" v-model="block_data.scratch_block[7].value"/>
                </div>
                <div class="input_block_child">
                    <p>{{block_data.scratch_block[8].label}}</p><input type="text" v-model="block_data.scratch_block[8].value" placeholder="25"/>
                </div>
            </div>
            <div class="input_block">
                <div class="input_block_child">
                    <p>{{block_data.scratch_block[9].label}}</p><input maxlength="10" type="text" v-model="block_data.scratch_block[9].value"/>
                </div>
                <div class="input_block_child">
                    <p>{{block_data.scratch_block[10].label}}</p><input type="text" v-model="block_data.scratch_block[10].value" placeholder="50"/>
                </div>
            </div>
            <div class="input_block" style="border: solid 1px #2699FB;">
                <div class="input_block_file">
                    <label>{{block_data.scratch_block[11].label}}</label>
                    <select v-model="block_data.scratch_block[11].value" style="width: 100%;">
                        <option v-for="option in block_data.scratch_block[11].option" v-bind:value="option.value" v-bind:key="option.id">
                          {{ option.symbol }}
                        </option>
                    </select>
                </div>
            </div>
            <!-- <div class="input_block" style="border: solid 1px #2699FB;">
                <div class="input_block_file">
                    <label>{{block_data.scratch_block[12].label}}</label>
                    <select v-model="block_data.scratch_block[12].value" style="width: 100%;">
                        <option v-for="option in block_data.scratch_block[12].option" v-bind:value="option.value" v-bind:key="option.id">
                          {{ option.symbol }}
                        </option>
                    </select>
                </div>
            </div> -->
            <div class="input_block" style="border: solid 1px #2699FB;">
                <div class="input_block_file">
                    <label>{{block_data.scratch_block[15].label}}</label>
                    <select v-model="block_data.scratch_block[15].value" style="width: 100%;">
                        <option v-for="option in block_data.scratch_block[15].option" v-bind:value="option.value" v-bind:key="option.id">
                          {{ option.symbol }}
                        </option>
                    </select>
                </div>
            </div>
            <!-- <div class="input_block" style="border: solid 1px #2699FB;">
                <div class="input_block_file">
                    <label>{{block_data.scratch_block[16].label}}</label>
                    <select v-model="block_data.scratch_block[16].value" style="width: 100%;">
                        <option v-for="option in block_data.scratch_block[16].option" v-bind:value="option.value" v-bind:key="option.id">
                          {{ option.symbol }}
                        </option>
                    </select>
                </div>
            </div> -->
            <div class="input_block date_input">
                <div class="input_block_child">
                    <p>開始日</p><input type="datetime-local" v-model="block_data.scratch_block[13].value"/>
                </div>
                <div style="align-self: center;">
                    <a style="margin: 30px;">〜</a>
                </div>
                <div class="input_block_child">
                    <p>終了日</p><input type="datetime-local" v-model="block_data.scratch_block[14].value"/>
                </div>
            </div>
            <div class="preview_box">
                <!-- <img v-if="block_data.scratch_block[11].value == '1'" src="/img/scratch_bg1.png">
                <img v-if="block_data.scratch_block[11].value == '2'" src="/img/scratch_bg2.png">
                <img v-if="block_data.scratch_block[11].value == '3'" src="/img/scratch_bg3.png">
                <img v-if="block_data.scratch_block[11].value == '4'" src="/img/scratch_bg4.png"> -->
            </div>
            <!-- <div class="input_block" style="border: solid 1px #2699FB;">
                <div class="input_block_file">
                    <label>背景画像</label>
                    <p @click="file_select">{{file_text}}</p>
                    <input id="file-upload" type="file" @change="changeFile" style="display: none;"/>
                </div>
            </div> -->
            <p>※ 枚数をゼロにすると、賞品が表示されなくなります</p>
            <p>※ 賞品の文字数は10文字までです</p>
            <section id="menu_button">
                <div class="button_box">
                    <button class="btn_blue" @click="post_data">新規登録</button>
                </div>
            </section>
            <p class="error_message" v-for="error in error_messages">{{error}}</p>
        </section>
      </div>
    </div>
    <FooterBlock></FooterBlock>
  </template>
  
<script>
import imageCompression from "browser-image-compression";
export default {
    components: {
    },
    data() {
        return {
            file_text:"ファイルを選択▼ (画像の比率は450:900を推奨しています)",
            error_messages:[],
            uploadfiles:[],
            block_data:{
                query_param:{},
                loading_block:{
                    is_loading:false
                },
                return_block:{
                    return_page_name:"UserTopPage",
                    return_page_is_query:false,
                    page_guides:[
                        {
                            name:"PageNameScratchAdd",
                            link:"",
                            is_query:false,
                        },
                    ],
                },
                scratch_block:[
                    {
                        label:"タイトル",
                        model_name:"title",
                        type:"text",
                        value:"",
                        place_holder:"新春割引キャンペーン！",
                    },
                    {
                        label:"1等",
                        model_name:"first_prize_label",
                        type:"text",
                        value:"",
                        place_holder:"50% OFF!!",
                    },
                    {
                        label:"枚数",
                        model_name:"1p_start_count",
                        type:"text",
                        value:"",
                        place_holder:"2",
                    },
                    {
                        label:"2等",
                        model_name:"second_prize_label",
                        type:"text",
                        value:"",
                        place_holder:"20% OFF!!",
                    },
                    {
                        label:"枚数",
                        model_name:"2p_start_count",
                        type:"text",
                        value:"",
                        place_holder:"8",
                    },
                    {
                        label:"3等",
                        model_name:"third_prize_label",
                        type:"text",
                        value:"",
                        place_holder:"10% OFF!!",
                    },
                    {
                        label:"枚数",
                        model_name:"3p_start_count",
                        type:"text",
                        value:"",
                        place_holder:"20",
                    },
                    {
                        label:"4等",
                        model_name:"fourth_prize_label",
                        type:"text",
                        value:"",
                        place_holder:"5% OFF!!",
                    },
                    {
                        label:"枚数",
                        model_name:"4p_start_count",
                        type:"text",
                        value:"",
                        place_holder:"70",
                    },
                    {
                        label:"5等",
                        model_name:"fifth_prize_label",
                        type:"text",
                        value:"",
                        place_holder:"5% OFF!!",
                    },
                    {
                        label:"枚数",
                        model_name:"5p_start_count",
                        type:"text",
                        value:"",
                        place_holder:"70",
                    },
                    {
                        label:"背景",
                        model_name:"template_id",
                        type:"list",
                        value:"1",
                        option:[],
                    },
                    {
                        label:"リセット",
                        model_name:"reset_status",
                        type:"list",
                        value:"2",
                        option:[
                            {id:1,value:1,symbol:"1日に一回",},
                            {id:2,value:2,symbol:"なし(1回限り)",},
                        ],
                    },
                    {
                        label:"開始日",
                        model_name:"start_date",
                        type:"text",
                        value:"",
                    },
                    {
                        label:"終了日",
                        model_name:"end_date",
                        type:"text",
                        value:"",
                    },
                    {
                        label:"5等の扱い",
                        model_name:"miss_prize_type",
                        type:"list",
                        value:"1",
                        option:[
                            {id:1,value:0,symbol:"景品なし",},
                            {id:2,value:1,symbol:"景品あり",},
                        ],
                    },
                    {
                        label:"オプション",
                        model_name:"charged_option",
                        type:"list",
                        value:"1",
                        option:[
                            {id:1,value:0,symbol:"無料",},
                            {id:2,value:1,symbol:"有料",},
                        ],
                    },
                ]
            }
        }
    },
    created () {
        this.process()
    },
    methods: {
        async process() {
            let response = await this.$commonFunction.axios_get('user/userinfo/design/list')
            if(response.status == 200){
                let select_list = this.$commonFunction.create_select_list(response.data.item_data,"title")
                let target_index = this.$commonFunction.return_item_index(this.block_data.scratch_block,"template_id")
                let select_value =  select_list[0] ?  select_list[0].value: ""
                this.block_data.scratch_block[target_index].value = select_value
                this.block_data.scratch_block[target_index].option = select_list
            } else if (response.status == 500){
                this.$router.push({name:"ServerErrorPage"});
            }
        },
        // データ投稿
        async post_data(){
            this.block_data.loading_block.is_loading = true
            
            let post_data = {}
            for (const item_model of this.block_data.scratch_block){
                post_data[item_model.model_name] = item_model.value 
            } 
            let is_error = false
            
            if(post_data.title == ""){
                is_error = true
                this.error_messages.push("タイトルを入力してください")
            }
            if(post_data.start_date == "" || post_data.end_date == ""){
                is_error = true
                this.error_messages.push("開始〜終了までの期間を設定してください")
            }
            post_data["1p_start_count"] = post_data["1p_start_count"] == "" ? "0":post_data["1p_start_count"]
            post_data["2p_start_count"] = post_data["2p_start_count"] == "" ? "0":post_data["2p_start_count"]
            post_data["3p_start_count"] = post_data["3p_start_count"] == "" ? "0":post_data["3p_start_count"]
            post_data["4p_start_count"] = post_data["4p_start_count"] == "" ? "0":post_data["4p_start_count"]
            post_data["5p_start_count"] = post_data["5p_start_count"] == "" ? "0":post_data["5p_start_count"]
            post_data["first_prize_label"] = post_data["first_prize_label"] == "" ? "-":post_data["first_prize_label"]
            post_data["second_prize_label"] = post_data["second_prize_label"] == "" ? "-":post_data["second_prize_label"]
            post_data["third_prize_label"] = post_data["third_prize_label"] == "" ? "-":post_data["third_prize_label"]
            post_data["fourth_prize_label"] = post_data["fourth_prize_label"] == "" ? "-":post_data["fourth_prize_label"]
            post_data["fifth_prize_label"] = post_data["fifth_prize_label"] == "" ? "-":post_data["fifth_prize_label"]
            
            if(!is_error){
                let response = await this.$commonFunction.axios_post(
                    'user/scratch/add',
                    post_data,
                )
                if(response.status == 200){
                    this.$router.push({name:"UserTopPage"});
                } else if (response.status == 404){
                    this.error_messages.push("ErrorPostFailed")
                } else if (response.status == 500){
                    this.$router.push({name:"ServerErrorPage"});
                }
            }

            
            this.block_data.loading_block.is_loading = false     
        },
    },
};
</script>

<style>
    #scratch_block{width: 70%;margin:  0 auto;}
    .input_block{display: flex;justify-content: space-between;margin: 20px auto;}
    .input_block_child{width: 47%;display: flex;justify-content: center;}
    .input_block_child:nth-child(1) {width: 68%;}
    .input_block_child:nth-child(2) {width: 28%;}
    .input_block p{width: 20%;color: white;background-color: #2699FB;padding: 15px;text-align: center;font-weight: bold;}
    .input_block label{width: 20%;color: white;background-color: #2699FB;padding: 15px;text-align: center;font-weight: bold;}
    .input_block_child p{width: 33%;}
    .input_block input{padding-left: 10px;width: 80%;border: 1px solid #2699FB;}

    .input_block_file{width: 100%;display: flex;justify-content: flex-start;}
    .input_block_file p{color: #2699FB;text-align: left;padding-left: 10px;width: 70%;background: transparent;}
    .error_message{text-align: center;color: red;}

    .preview_box img{width: 150px;} 

    .date_input{
        justify-content: start;
    }
    .date_input .input_block_child{
        width: 35%;
    }
</style>